import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { useGoogleLogin } from "@react-oauth/google";
import env from "../../../config/env";
import { Navbar } from "src/components/shared";
import { useTranslation } from "react-i18next";

import "./login.css";
import { useStore } from "../../../store";
import Container from "src/components/shared/Container";

function Logine() {
  const { t } = useTranslation();
  const LinkedInApi = {
    clientId: env.LINKEDIN_CLIENT_ID,
    redirectUrl: env.LINKEDIN_REDIRECT_URL,
    oauthUrl: env.LINKEDIN_OAUTH_URL,
    scope: env.LINKEDIN_SCOPE,
    state: env.LINKEDIN_STATE,
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userStore } = useStore();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState<string>("/");
  const isLinkedInAuthRef = useRef<boolean>(false);
  const verificationStatusParam = searchParams.get("verificationStatus");
  const redirectionUrlParam = searchParams.get("redirectionUrl");
  const hasShownToast = useRef(false);

  useEffect(() => {
    if (!hasShownToast.current) {
      if (verificationStatusParam) {
        if (verificationStatusParam === "success") {
          toast.success(
            "Your email has been successfully verified. You can login to your account"
          );
        } else {
          toast.error(
            "Something went wrong while verifying your email. Please try again later."
          );
        }
        hasShownToast.current = true;
      }

      if (redirectionUrlParam) {
        redirectionUrlParam === "home"
          ? setRedirectionUrl("/")
          : setRedirectionUrl(`/${redirectionUrlParam}`);
      }
    }

    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: "code", code: code }, "*");
      window.close();
    }
    window.addEventListener("message", handlePostMessage);
    return () => window.removeEventListener("message", handlePostMessage);
  }, [verificationStatusParam, redirectionUrlParam]);

  const handlePostMessage = (event: any) => {
    if (event.data.type === "code" && !isLinkedInAuthRef.current) {
      isLinkedInAuthRef.current = true;
      const { code } = event.data;
      handleLinkedInSuccess(code);
    }
  };

  const getCodeFromWindowURL = (url: any) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleLogin(tokenResponse),
  });

  const validateInputs = () => {
    let valid = true;
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else if (/[^a-zA-Z0-9@._-]/.test(email)) {
      setEmailError("Email contains invalid characters");
      valid = false;
    } else if (/\.\./.test(email)) {
      setEmailError("Email cannot contain consecutive dots");
      valid = false;
    } else if (/^\./.test(email) || /\.$/.test(email)) {
      setEmailError("Email cannot start or end with a dot");
      valid = false;
    } else if (/\s/.test(email)) {
      setEmailError("Email cannot contain spaces");
      valid = false;
    } else if (!/@/.test(email)) {
      setEmailError("Invalid email format");
      valid = false;
    } else if (!/^[^@]+@/.test(email)) {
      setEmailError("Email local part is missing");
      valid = false;
    } else if (!/^[^@\s]+@[^@\s]+\./.test(email)) {
      setEmailError("Email domain is missing or invalid");
      valid = false;
    } else if (!/^[^@\s]+@[^@\s]+\.[a-zA-Z]+$/.test(email)) {
      setEmailError("Invalid email format");
      valid = false;
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else if (password.length < 8) {
      setPasswordError("Password is too short");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = async () => {
    if (!validateInputs()) return;

    userStore
      .login(email.trim(), password)
      .then((token) => {
        if (token && token !== null) {
          navigate(redirectionUrl);
        } else {
          toast("Invalid email or password!");
          setEmailError("Invalid email or password.");
          setPasswordError("Invalid email or password.");
        }
      })
      .catch((error) => {
        if (
          error.response.data.error.code === "LOGIN_FAILED_EMAIL_NOT_VERIFIED"
        ) {
          toast.error(
            "Email not verified. Please check your inbox for verification link."
          );
        } else {
          toast.error("Invalid email or password!");
        }
      });
  };

  const handleGoogleLogin = async (response: any) => {
    try {
      const token = response.access_token;
      const res = await userStore.loginWithGoogle(token);

      if (res) {
        navigate("/");
      } else {
        toast("Google login failed!");
      }
    } catch (error: any) {
      console.log("error", error);

      toast(error.response.data.error.message);
    }
  };

  const handleLinkedInSuccess = async (code: any) => {
    try {
      const linkedinToken = code;
      const res = await userStore.loginWithLinkedIn(linkedinToken);
      if (res) {
        navigate("/");
      } else {
        toast("LinkedIn Sign in failed!");
      }
    } catch (error: any) {
      toast(error.response.data.error.message);
    }
  };

  const showPopup = () => {
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrlComplete = `${oauthUrl}&client_id=${encodeURIComponent(
      clientId!
    )}&scope=${encodeURIComponent(
      scope!
    )}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlComplete,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const VisibleIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3162 13.3078C16.9816 5.89741 7.01879 5.89741 3.68413 13.3078C3.51415 13.6855 3.07014 13.8539 2.69241 13.6839C2.31468 13.514 2.14627 13.07 2.31624 12.6922C6.18158 4.10259 17.8188 4.10259 21.6841 12.6922C21.8541 13.07 21.6857 13.514 21.308 13.6839C20.9302 13.8539 20.4862 13.6855 20.3162 13.3078Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 14C15.75 11.9289 14.0711 10.25 12 10.25C9.92893 10.25 8.25 11.9289 8.25 14C8.25 16.0711 9.92893 17.75 12 17.75C14.0711 17.75 15.75 16.0711 15.75 14Z"
        fill="black"
      />
    </svg>
  );

  const InvisibleIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5001 16L17.0249 12.6038"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.5V14"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 16L6.96895 12.6124"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8C6.6 16 17.4 16 21 8"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <>
      <Navbar />

      <ToastContainer theme="dark" />

      <Container>
        <div className="w-full flex justify-center items-center mt-8">
          <form className="flex flex-col text-center bg-white rounded-3xl px-16 pt-7 max-sm:px-8 h-[708px] w-[494px] border border-border-black-5 mb-4">
            <h1 className="font-inter sm:text-[22px] md:text-[32px] lg:text-[32px] xl:text-[32px] tracking-[-0.01em] text-center px-3.5 whitespace-nowrap leading-[38px] font-bold">
              <span className="colored_span font-inter sm:text-[22px] md:text-[32px] lg:text-[32px] xl:text-[32px] tracking-[-0.01em] text-center whitespace-nowrap leading-[38px] font-bold">
                {t("login.title")}
              </span>{" "}
              {t("login.title2")}
            </h1>

            <h4 className="mb-6 mt-1 text-base leading-[25px] font-normal text-[#1B0209]">
              {t("login.subtitle")}
            </h4>
            <label
              htmlFor="email"
              className="text-start text-xs leading-6 tracking-normal font-medium to-gray-800 outline-none"
            >
              {t("login.emailAddress")}
              <span className="text-red-600">*</span>
            </label>
            <div className="relative mb-2">
              <input
                id="email"
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setIsEmailFocused(true)}
                onBlur={() => setIsEmailFocused(false)}
                placeholder={t("login.emailPlaceholder") || ""}
                className={`h-10 sm:h-[42px] md:h-[48px] lg:h-[48px] flex items-start w-full px-5 text-sm leading-6 tracking-normal font-normal placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl focus:outline-none 
    ${
      emailError
        ? "border-[#FF3E3E] border-[1px]"
        : isEmailFocused || email
        ? "border-[#E83C70] border-[2px]"
        : "border-[#DFDFDF] border-[1px]"
    }`}
              />

              {emailError && (
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500 px-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.0419922 8.99999C0.0419922 4.05244 4.05277 0.0416565 9.00033 0.0416565C13.9479 0.0416565 17.9587 4.05244 17.9587 8.99999C17.9587 13.9475 13.9479 17.9583 9.00033 17.9583C4.05277 17.9583 0.0419922 13.9475 0.0419922 8.99999ZM9.00033 4.20832C9.3455 4.20832 9.62533 4.48815 9.62533 4.83332V9.83332C9.62533 10.1785 9.3455 10.4583 9.00033 10.4583C8.65515 10.4583 8.37533 10.1785 8.37533 9.83332V4.83332C8.37533 4.48815 8.65515 4.20832 9.00033 4.20832ZM9.47322 13.584C9.70413 13.3274 9.68334 12.9322 9.42677 12.7013C9.1702 12.4704 8.77502 12.4912 8.54411 12.7477L8.53577 12.757C8.30486 13.0136 8.32566 13.4088 8.58223 13.6397C8.8388 13.8706 9.23398 13.8498 9.46489 13.5932L9.47322 13.584Z"
                      fill="#D22121"
                    />
                  </svg>
                </span>
              )}
            </div>
            {emailError && (
              <span className="text-[#D22121] text-xs font-medium leading-6  mb-3 text-left block ">
                {emailError}
              </span>
            )}
            <label
              htmlFor="password"
              className="text-start text-xs leading-6 tracking-normal font-medium to-gray-800 outline-none"
            >
              {t("login.password")}
              <span className="text-red-600">*</span>
            </label>
            <div className="relative mb-2">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setIsPasswordFocused(true)}
                onBlur={() => setIsPasswordFocused(false)}
                placeholder={t("login.passwordPlaceholder") || ""}
                className={`h-10 sm:h-[42px] md:h-[48px] lg:h-[48px] flex items-center w-full px-5 text-sm font-medium placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl focus:outline-none 
    ${
      passwordError
        ? "border-[#FF3E3E] border-[1px]"
        : isPasswordFocused || password
        ? "border-[#E83C70] border-[2px]"
        : "border-[#DFDFDF] border-[1px]"
    }`}
              />

              {passwordError && (
                <span className="absolute right-10 top-1/2 transform -translate-y-1/2 px-1 text-red-500">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.0419922 8.99999C0.0419922 4.05244 4.05277 0.0416565 9.00033 0.0416565C13.9479 0.0416565 17.9587 4.05244 17.9587 8.99999C17.9587 13.9475 13.9479 17.9583 9.00033 17.9583C4.05277 17.9583 0.0419922 13.9475 0.0419922 8.99999ZM9.00033 4.20832C9.3455 4.20832 9.62533 4.48815 9.62533 4.83332V9.83332C9.62533 10.1785 9.3455 10.4583 9.00033 10.4583C8.65515 10.4583 8.37533 10.1785 8.37533 9.83332V4.83332C8.37533 4.48815 8.65515 4.20832 9.00033 4.20832ZM9.47322 13.584C9.70413 13.3274 9.68334 12.9322 9.42677 12.7013C9.1702 12.4704 8.77502 12.4912 8.54411 12.7477L8.53577 12.757C8.30486 13.0136 8.32566 13.4088 8.58223 13.6397C8.8388 13.8706 9.23398 13.8498 9.46489 13.5932L9.47322 13.584Z"
                      fill="#D22121"
                    />
                  </svg>
                </span>
              )}
              <span
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
                style={{ width: "24px", height: "24px" }}
              >
                {showPassword ? <InvisibleIcon /> : <VisibleIcon />}
              </span>
            </div>

            {passwordError && (
              <span className="text-[#D22121] text-xs font-medium leading-6 mb-3 text-left block">
                {passwordError}
              </span>
            )}

            <div className="flex items-center justify-between mb-8 w-full">
              <div className="flex items-center">
                <label
                  htmlFor="check-2"
                  className="flex items-center cursor-pointer relative"
                >
                  <input
                    type="checkbox"
                    className="peer h-4 w-4 cursor-pointer transition-all appearance-none rounded-[4px] shadow hover:shadow-md border border-black checked:bg-white checked:border-[#E83C70]"
                    id="check-2"
                  />
                  <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      width="9"
                      height="9"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="8" height="8" rx="2" fill="#E83C70" />
                    </svg>
                  </span>
                </label>
                <span className="ml-1 text-start text-sm leading-6 tracking-normal font-normal text-primary-gray-500 outline-none ">
                  {t("login.rememberMe")}
                </span>
              </div>
              <a
                href="/forgot-password"
                className="text-start text-sm leading-6 tracking-normal font-normal outline-none text-[#E83C70] hover:text-[#0f0f0f] underline"
              >
                {t("login.forgotPassword")}
              </a>
            </div>

            <button
              type="button"
              onClick={() => handleSubmit()}
              className="text-[#FFFAFB] h-12 min-h-12 justify-center w-full bg-[#E83C70] border-2 border-transparent hover:border-[#E83C70] hover:text-[#E83C70] hover:bg-white focus:ring-4 focus:outline-none focus:ring-[#FFFFFF]/50 font-semibold rounded-xl leading-6 text-sm px-5 text-center inline-flex items-center  me-2 mt-0"
            >
              {t("login.loginButton")}
            </button>

            <hr className="h-px mt-8 mb-8 bg-gray-200 border-0 dark:bg-gray-200" />
            <button
              onClick={() => googleLogin()}
              type="button"
              className="flex items-center justify-center text-primary-gray-850 h-12 min-h-8 border-[1px] border-gray-200 w-full bg-[#FCFCFF] hover:bg-[#FCFCFF]/90 focus:ring-4 focus:outline-none focus:ring-[#FFFFFF]/50 rounded-xl text-sm font-semibold leading-6 mb-3 px-3"
            >
              <img
                className="h-5 mr-2"
                src="https://raw.githubusercontent.com/Loopple/loopple-public-assets/main/motion-tailwind/img/logos/logo-google.png"
                alt="Google Logo"
              />
              <span className="font-bold">{t("login.continueWithGoogle")}</span>
            </button>

            <button
              onClick={showPopup}
              type="button"
              className="flex items-center justify-center text-primary-gray-850 h-12 min-h-8 border-[1px] border-gray-200 w-full bg-[#FCFCFF] hover:bg-[#FCFCFF]/90 focus:ring-4 focus:outline-none focus:ring-[#E83C70]/50  rounded-xl text-sm font-semibold leading-6 mb-2 px-3"
            >
              <img
                className="h-5 mr-2"
                src="https://raw.githubusercontent.com/Loopple/loopple-public-assets/refs/heads/main/motion-tailwind/img/icons/linkedin-app-icon.png"
                alt="LinkedIn Logo"
              />
              {t("login.continueWithLinkedIn")}
            </button>

            <p className="text-sm leading-6 font-normal text-[#1B0209] pt-5">
              {t("login.notRegisteredYet")}{" "}
              <a
                href="/register"
                className="font-normal text-sm leading-6 text-pink-500 hover:text-[#1B0209] underline"
              >
                {t("login.createAccount")}
              </a>
            </p>
          </form>
        </div>
      </Container>
    </>
  );
}

export default observer(Logine);
