import React, { useState, useRef, useEffect } from "react";

interface EditorState {
  html: string;
  range: Range | null;
}

interface IProps {
  html: string;
  setHtml: (html: string) => void;
}

const RichTextEditor: React.FC<IProps> = ({ html, setHtml }) => {
  // const [html, setHtml] = useState("");
  const [selectionRange, setSelectionRange] = useState<Range | null>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const undoStack = useRef<{ html: string; range: Range | null }[]>([]);
  const redoStack = useRef<{ html: string; range: Range | null }[]>([]);
  const isUndoRedoAction = useRef(false);

  useEffect(() => {
    undoStack.current.push({ html: "", range: null });
    if (editorRef.current) {
      editorRef.current.innerHTML = html;
    }
  }, []);

  useEffect(() => {
    if (isUndoRedoAction.current) {
      restoreSelectionRange(selectionRange);
    }
  }, [html]);

  const saveToHistory = () => {
    if (!isUndoRedoAction.current) {
      const range = getSelectionRange();
      undoStack.current.push({ html, range });
      redoStack.current = [];
    }
  };

  const getSelectionRange = (): Range | null => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0);
    }
    return null;
  };

  const restoreSelectionRange = (range: Range | null) => {
    if (range) {
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && !e.shiftKey) {
      switch (e.key.toLowerCase()) {
        case "z":
          e.preventDefault();
          handleUndo();
          return;
        case "b":
          e.preventDefault();
          document.execCommand("bold");
          saveToHistory();
          return;
        case "i":
          e.preventDefault();
          document.execCommand("italic");
          saveToHistory();
          return;
        case "u":
          e.preventDefault();
          document.execCommand("underline");
          saveToHistory();
          return;
        default:
          break;
      }
    }

    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key.toLowerCase() === "z") {
      e.preventDefault();
      handleRedo();
      return;
    }

    if (e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      document.execCommand("insertLineBreak");
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      const newHtml = editorRef.current.innerHTML;
      setHtml(newHtml);
      saveToHistory();
    }
  };

  const handleUndo = () => {
    if (undoStack.current.length > 1) {
      const currentState = undoStack.current.pop();
      redoStack.current.push(currentState!);
      const previousState = undoStack.current[undoStack.current.length - 1];
      isUndoRedoAction.current = true;
      setHtml(previousState.html);
      setSelectionRange(previousState.range);
      if (editorRef.current) editorRef.current.innerHTML = previousState.html;
      isUndoRedoAction.current = false;
    }
  };

  const handleRedo = () => {
    if (redoStack.current.length > 0) {
      const nextState = redoStack.current.pop();
      undoStack.current.push(nextState!);
      isUndoRedoAction.current = true;
      if (nextState) {
        setHtml(nextState.html);
        setSelectionRange(nextState.range);
        if (editorRef.current) editorRef.current.innerHTML = nextState.html;
      }
      isUndoRedoAction.current = false;
    }
  };

  return (
    <div
      className="w-full min-h-[120px] p-3 border border-primary-gray-200 outline-none rounded-lg text-sm"
      ref={editorRef}
      contentEditable
      onInput={handleInput}
      onKeyDown={handleKeyDown}
    />
    // <div
    //   dangerouslySetInnerHTML={{ __html: html }}
    //   style={{
    //     border: "1px solid #ccc",
    //     padding: "10px",
    //     marginTop: "10px",
    //     minHeight: "100px",
    //     whiteSpace: "pre-wrap",
    //   }}
    // />
  );
};

export default RichTextEditor;
