import { observer } from "mobx-react";

import { useStore } from "../../../../store";
import { Button, Popup } from "../../..";
import styles from "./about.module.css";
import { useState } from "react";
import env from "../../../../config/env";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface IProps {
  type: "course" | "class";
}

function AboutCourse({ type }: IProps) {
  const { courseStore } = useStore();
  const { userStore } = useStore();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const course: any = courseStore.courseDetails;

  const courseFileName =
    course?.file?.name || course?.sessionCourse?.file?.name || "";

  const courseForWho = course?.forWho || course?.sessionCourse?.forWho || "";

  const downloadSyllabus = async () => {
    if (userStore.user) {
      let syllabusUrl = `${env.API}/Container2s/file/download/${courseFileName}`;

      const response = await courseStore.downloadSyllabus(syllabusUrl);

      if (response.error) {
        if (response.data.code === "ERR_BAD_REQUEST") {
          toast.error("File not found.");
        } else {
          toast.error("An error has occured. Please try again later.");
        }
      } else {
        const blob = await response.data.data;

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = courseFileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } else {
      setIsOpen(true);
    }
  };

  const getCourseDifficulty = (course: any) => {
    if (course) {
      if (course.difficulty) {
        switch (course.difficulty) {
          case "Beginner": {
            return t("messages.beginnerLevel");
          }
          case "Intermediate": {
            return t("messages.intermediateLevel");
          }
          case "Advanced": {
            return t("messages.AdvancedLevel");
          }
          default: {
            return t("messages.beginnerLevel");
          }
        }
      } else {
        return t("messages.beginnerLevel");
      }
    } else {
      return t("messages.beginnerLevel");
    }
  };

  return (
    <div className={styles.about_course} id="about-course">
      <ToastContainer theme="dark" />
      <div className={styles.about_course_content}>
        <h3 className={styles.about_course_title}>
          {" "}
          {t("buttons.about")} {t("buttons.course")}{" "}
        </h3>

        <div className="w-full flex flex-col md:flex-row justify-between items-start gap-8">
          <div className={styles.about_course_details}>
            {/* <h4>{course?.title}</h4> */}
            {courseForWho !== "" && (
              <>
                {/* <h5> {t("messages.courseContentTitle")}:</h5> */}
                <p>{courseForWho}</p>
              </>
            )}
          </div>

          <div className={styles.about_course_list}>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/authentic-certificate.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>{t("messages.industryCertifiedTtile")}</h6>
                <span>{t("messages.industryCertifiedContent")}</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/onsite-training.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>{t("messages.interactiveTrainingTitle")}</h6>
                <span>{t("messages.interactiveTrainingContent")}</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/community.png"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>{t("messages.networkingTitle")}</h6>
                <span>{t("messages.networkingContent")}</span>
              </div>
            </div>
            <div className={styles.about_course_list_item}>
              <div className={styles.about_course_list_item_icon}>
                <img
                  width={24}
                  height={24}
                  alt="list icon"
                  src="/images/icons/beginner-level.svg"
                />
              </div>
              <div className={styles.about_course_list_item_text}>
                <h6>{getCourseDifficulty(course)}</h6>
                <span>{t("messages.levelContent")}</span>
              </div>
            </div>

            <div>
              {courseFileName && userStore.user ? (
                <span className={styles.btn_text}>
                  {t("messages.fullDetailsQuestion")}
                </span>
              ) : null}
              {courseFileName !== "" && (
                <div className="w-full md:w-96">
                  <Button
                    type="secondary"
                    text={t("buttons.downloadSyllabus")}
                    onClick={() => downloadSyllabus()}
                    icon="/images/icons/download.svg"
                    customStyle={{ marginTop: "0.5em" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <Popup
          type={type}
          isOpen={isOpen}
          course={course.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default observer(AboutCourse);
