import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useStore } from ".";

interface ProtectedRouteProps {
  children: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { userStore } = useStore();
  const loggedIn: boolean =
    localStorage.getItem("token") === null ? false : true;
  // const registrationCompleted: boolean =
  //   userStore?.user?.completedRegistrationSteps === true;
  const registrationCompleted: boolean =
    localStorage.getItem("registrationStep") &&
    Number(localStorage.getItem("registrationStep")) === 6
      ? true
      : false;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      loggedIn &&
      !registrationCompleted &&
      localStorage.getItem("registrationUser") !== null &&
      location.pathname !== "/register"
    ) {
      navigate("/register", { replace: true });
    }
  }, [navigate, registrationCompleted, location, loggedIn]);

  return children;
};

export default ProtectedRoute;
