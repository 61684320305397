import { useState } from "react";
import { observer } from "mobx-react";

import styles from "./navbar.module.css";
import { useTranslation } from "react-i18next";

function CourseNavBar() {
  const { t } = useTranslation();

  const [active, setActive] = useState<number>(1);

  const onClick = (index: number, link: string) => {
    setActive(index);
    const element = document.getElementById(link);
    if (element) {
      const navbarHeight =
        document.querySelector(`.navbar-links`)?.clientHeight || 67;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY - navbarHeight;
      console.log(elementPosition, navbarHeight);
      window.scrollTo({ top: elementPosition, behavior: "smooth" });
    }
  };

  return (
    <div className="navbar-links px-5 sm:px-0 py-2.5 sm:pt-5 bg-primary-gray-50">
      <div className={styles.course_navbar_links}>
        <div
          onClick={() => onClick(1, "about-course")}
          className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 1
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
        >
          <span>{t("buttons.about")}</span>
        </div>

        <div
          onClick={() => onClick(2, "course-content")}
          className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 2
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
        >
          <span> {t("buttons.content")} </span>
        </div>

        <div
          onClick={() => onClick(7, "reviews")}
          className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 7
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
        >
          <span> {t("messages.review")} </span>
        </div>

        <div
          onClick={() => onClick(8, "faqs")}
          className={`hidden sm:block
                            ${styles.course_navbar_item}
                            ${
                              active === 8
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
        >
          <span> {t("buttons.faqs")} </span>
        </div>
      </div>

      {/* {type === "course" && (
          <Button
            type="primary"
            text={t("buttons.participate")}
            width="170px"
            onClick={() => participate()}
          />
        )} */}
    </div>
  );
}

export default observer(CourseNavBar);
