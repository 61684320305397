import React, { useRef } from "react";

interface InputProps {
  files: File[];
  setFiles: (files: File[]) => void;
  label: string;
  required?: boolean;
  maxFiles?: number;
  minFiles?: number;
  maxFileSize?: number;
  types?: string;
}

const FilesInput: React.FC<InputProps> = ({
  files,
  setFiles,
  label,
  required,
  maxFiles = 4,
  minFiles = 1,
  maxFileSize = 6,
  types = ".jpg, .jpeg, .png, .pdf",
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const handleAttachFile = (e: any) => {
    const newFiles: File[] = Array.from(e.target.files);
    const existingFileNames = files.map((file) => file.name);
    const filteredNewFiles = newFiles.filter(
      (file: File) =>
        !existingFileNames.includes(file.name) &&
        file.size <= maxFileSize * 1024 * 1024
    );
    const oversizedFiles = newFiles.filter(
      (file: File) => file.size > maxFileSize * 1024 * 1024
    );
    if (oversizedFiles.length > 0) {
      alert(
        `Some files exceed the ${maxFileSize}MB size limit and were not added.`
      );
    }
    const totalFiles = files.length + filteredNewFiles.length;
    if (totalFiles > maxFiles) {
      alert(`You can only upload a maximum of ${maxFiles} files.`);
      setFiles([
        ...files,
        ...filteredNewFiles.slice(0, maxFiles - files.length),
      ]);
    } else {
      setFiles([...files, ...filteredNewFiles]);
    }
  };
  const triggerAttachFile = (): void => {
    fileUploadRef.current?.click();
  };

  const getFileExtension = (file: File) => {
    return file.name.split(".").pop();
  };
  const getFileExtensionBackground = (file: File) => {
    const extension = getFileExtension(file);
    switch (extension) {
      case "pdf":
        return "rgba(228, 91, 91, 1)";
      case "jpg":
        return "rgba(184, 91, 228, 1)";
      case "jpeg":
        return "rgba(228, 91, 173, 1)";
      case "png":
        return "rgba(91, 129, 228, 1)";
      default:
        return "rgba(228, 91, 173, 1)";
    }
  };

  const handleDownload = (file: File) => {
    window.open(URL.createObjectURL(file), "_blank");
  };

  return (
    <div className="flex flex-col gap-0.5">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div>
        {files.length > 0 ? (
          <div className="flex flex-col gap-3 p-4 sm:px-8 sm:py-5 items-center justify-center h-full w-full border border-primary-paradise-pink-500 rounded-xl bg-primary-paradise-pink-50">
            <div className="flex flex-col gap-3 w-full">
              {files.map((file, index) => (
                <div className="flex items-center justify-between" key={index}>
                  <div
                    className="flex items-center gap-3.5 cursor-pointer"
                    onClick={() => handleDownload(file)}
                  >
                    <div
                      className="flex items-center justify-center text-white text-[8px] font-extrabold size-8 rounded bg-black"
                      style={{
                        backgroundColor: getFileExtensionBackground(file),
                      }}
                    >
                      {getFileExtension(file)?.toUpperCase()}
                    </div>
                    <p className="text-body-small sm:text-body-medium">
                      {file.name}
                    </p>
                  </div>
                  <div className="flex items-center gap-3.5">
                    <span className="text-caption-text sm:font-caption-text-bold text-primary-paradise-pink-800">
                      {(file.size / (1024 * 1024)).toFixed(1)} MB
                    </span>
                    <img
                      className="size-5 cursor-pointer"
                      src="/images/icons/close-red.svg"
                      onClick={() =>
                        setFiles(files.filter((f, i) => i !== index))
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            <div
              className="flex items-center w-fit gap-2.5 px-4 py-1.5 cursor-pointer border border-primary-paradise-pink-500 rounded-xl transition-all duration-200 hover:bg-primary-paradise-pink-100 bg-primary-gray-50"
              onClick={triggerAttachFile}
            >
              <img src="/images/icons/upload.svg" width={16} height={16} />
              <span className="caption-text text-primary-paradise-pink-500">
                Upload more
              </span>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col items-center justify-center h-[125px] w-full cursor-pointer border border-primary-paradise-pink-500 rounded-xl transition-all duration-200 hover:bg-primary-paradise-pink-100 bg-primary-paradise-pink-50"
            onClick={triggerAttachFile}
          >
            <div className="flex flex-col items-center caption-text text-primary-paradise-pink-500">
              <img src="/images/icons/upload.svg" />
              <div>Upload Files (.pdf, .jpeg, .png)</div>
            </div>
          </div>
        )}
        <input
          className="hidden"
          type="file"
          ref={fileUploadRef}
          multiple
          accept={types}
          onChange={(e) => handleAttachFile(e)}
        />
      </div>
      <p className="caption-text">* Max file size: {maxFileSize}MB</p>
    </div>
  );
};

export default FilesInput;
