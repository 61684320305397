import React, { useRef } from "react";

interface InputProps {
  image: File | undefined;
  setImage: (image: File) => void;
  label: string;
  required?: boolean;
}

const ImageInput: React.FC<InputProps> = ({
  image,
  setImage,
  label,
  required,
}) => {
  const [dragging, setDragging] = React.useState(false);

  const imageUploadRef = useRef<HTMLInputElement>(null);

  const triggerFileInput = (): void => {
    imageUploadRef.current?.click();
  };
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setImage(file);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      setImage(file);
    } else {
      alert("File size exceeds 2MB");
    }
  };

  return (
    <div className="flex flex-col gap-0.5">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div>
        <div
          className={`flex flex-col items-center justify-center h-[125px] w-full cursor-pointer border border-primary-paradise-pink-500 rounded-xl transition-all duration-200 hover:bg-primary-paradise-pink-100 ${
            dragging
              ? "bg-primary-paradise-pink-100"
              : "bg-primary-paradise-pink-50"
          }`}
          onClick={triggerFileInput}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragEnter}
          onDrop={handleDrop}
        >
          <div className="flex flex-col items-center caption-text text-primary-paradise-pink-500">
            {dragging ? (
              <div className="text-center">Drop Your Picture Here</div>
            ) : image ? (
              <img
                className="rounded-full size-20 object-cover"
                src={URL.createObjectURL(image)}
                alt=""
              />
            ) : (
              <>
                <img src="/images/icons/upload.svg" />
                <div>Upload image</div>
              </>
            )}
          </div>
        </div>
        <input
          className="hidden"
          ref={imageUploadRef}
          type="file"
          id="picture"
          required={required}
          accept=".jpg, .jpeg, .png"
          onChange={handleImageChange}
        />
      </div>
      <p className="caption-text">* Max file size: 2MB</p>
    </div>
  );
};

export default ImageInput;
