import React, { useEffect } from "react";

import styles from "./blogs.module.css";
import Blog from "../BlogItem";
import { Button } from "src/components/shared";
import { CourseStore } from "src/store/course";
import { useStore } from "src/store";
import { useTranslation } from "react-i18next";

const Blogs: React.FC = () => {
  const { t } = useTranslation();
  const { courseStore } = useStore();

  const [isBlogsLoaded, setIsBlogsLoaded] = React.useState(false);
  const [blogsList, setBlogsList] = React.useState<any[]>([]);

  useEffect(() => {
    courseStore
      .getRecentBlogs()
      .then((res) => {
        if (res) {
          setBlogsList(res);
          setIsBlogsLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setBlogsList([]);
      });
  }, [setBlogsList, setIsBlogsLoaded]);

  return (
    <>
      {isBlogsLoaded && (
        <div className={styles.blogs}>
          <div className="text-body-large font-body-large-bold sm:text-subheading flex justify-between items-center">
          <h2>{t("blogs.heading")}</h2>
          <a
              href="https://blog.classx.tech/"
              target="_blank"
              className="hidden sm:block"
            >
              <Button
                type="outline"
                text={t("blogs.viewMore")}
                width="fit-content"
                customStyle={{ height: "50px" }}
              />
            </a>
          </div>

          <div className={styles.blogs_list}>
            {blogsList.map((blog, index) => (
              <Blog key={index} blog={blog} fill={blog.fill} />
            ))}
          </div>

          <a
            href="https://blog.classx.tech/"
            target="_blank"
            className="block sm:hidden"
          >
            <Button
              type="outline"
              text={t("blogs.viewMore")}
              width="fit-content"
              customStyle={{ height: "50px" }}
            />
          </a>
        </div>
      )}
    </>
  );
};

export default Blogs;
