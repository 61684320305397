import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./becomeMember.module.css";
import { Button } from "src/components/shared";
import { useTranslation } from "react-i18next";


const BecomeMember: React.FC = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { t } = useTranslation();


  const navigation = () => {
    if (token) {
      navigate("/search");
      window.scrollTo(0, 0);
    } else {
      navigate("/register");
    }
  };

  return (
    <div className={styles.container}>
      <h3 className="text-body-large font-body-large-bold sm:text-heading-large ">
      {t("becomeMember.heading.part1")}{" "}
        <span className="text-primary-paradise-pink-500">{t("becomeMember.heading.part2")}
        </span>{" "}
        <br />{t("becomeMember.heading.part3")}

      </h3>

      <p className="text-body-medium font-body-small sm:text-body-medium sm:font-body-medium mb-2.5">
      <br className="hidden sm:block" /> {t("becomeMember.subheading")}
      
      </p>

      <Button
        type="primary"
        text={t("becomeMember.joinNow")}
        width="fit-content"
        onClick={navigation}
      />
    </div>
  );
};

export default BecomeMember;
