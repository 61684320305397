import Classroom from "./classroom";
import "./styles.css";
import { useTranslation } from "react-i18next";


interface ClassroomsListProps {
  data: any[] | null;
  page: number;
  numberOfPages: number;
  count: number;
  handlePreviousPage: () => void;
  handleNextPage: () => void;
  handlePrivacy: (id: string, privacy: boolean) => void;
}

export default function ClassroomsList({
  data,
  page,
  count,
  numberOfPages,
  handleNextPage,
  handlePreviousPage,
  handlePrivacy,
}: ClassroomsListProps) {
  const { t } = useTranslation();

  return (
    <div className="tab-content">
      <div className="top">
      <h1>{t("classroomsList.allClassrooms")}</h1>
      <div className="features">
          <div className="icon">
            <img src="/images/icons/sort.svg" alt="" />
            {t("classroomsList.sort")}
          </div>
          <div className="icon">
            <img src="/images/icons/filter.svg" alt="" />
            {t("classroomsList.filter")}
          </div>
        </div>
      </div>

      <div className="classrooms">
        {data && data.length > 0 ? (
          data.map((classroom, index) => (
            <Classroom
              key={index}
              id={classroom.id}
              image={classroom.logo}
              name={classroom.name}
              capacity={classroom.size}
              description={classroom.description}
              location={classroom.address}
              isPublic={classroom.status === "Published"}
              handlePrivacy={() =>
                handlePrivacy(classroom.id, classroom.status !== "Published")
              }
            />
          ))
        ) : (
          <div className="nodata">
            <img src="/images/icons/nodata.svg" alt="" />
            <span>{t("classroomsList.noData")}</span>
          </div>
        )}
      </div>

      <div className="pagination">
        <span>
          {page} - {numberOfPages} of {count}
        </span>
        <button onClick={handlePreviousPage}>&lt;</button>
        <button onClick={handleNextPage}>&gt;</button>
      </div>
    </div>
  );
}
