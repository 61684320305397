import React from "react";

import styles from "./course.module.css";
import env from "../../../../config/env";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { formatDateTimeRange } from "../../../../utils/dateFormat";
import { observer } from "mobx-react";
import { formatCurrency } from "../../../../utils/formatCurrency";

interface IProps {
  type?: "course" | "class" | "search";
  course: {
    id: string;
    title: string;
    category: string;
    description: string;
    difficulty?: string;
    image: string;
    price: number;
    rating: number;
    ratingCount: number;
    startTime?: string;
    endTime?: string;
    location?: string;
    provider: {
      name: string;
      logo: string;
    };
    domains?: any[];
    isOnline?: boolean;
  };
  inSearch?: boolean;
}

const CourseDomains: React.FC<{
  domains: string[] | undefined;
  difficulty: string | undefined;
}> = ({ domains, difficulty }) => {
  return (
    <>
      {domains && domains.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {/* {domains?.map((domain: any, index: number) => (
            <span
              key={index}
              className={
                styles.course_domain +
                " caption-text-bold text-primary-purple-500 px-4 py-1 bg-primary-purple-100 rounded"
              }
            >
              {domain}
            </span>
          ))} */}
          <span
            className={
              styles.course_domain +
              " caption-text-bold text-primary-purple-500 px-4 py-1 bg-primary-purple-100 rounded"
            }
          >
            {domains[0]}
          </span>
          {difficulty && (
            <span
              className={
                styles.course_domain +
                " caption-text-bold text-primary-purple-500 px-4 py-1 bg-primary-purple-100 rounded"
              }
            >
              {difficulty}
            </span>
          )}
        </div>
      )}
    </>
  );
};

const CourseReviews: React.FC<{ rating: number; ratingCount: number }> = ({
  rating,
  ratingCount,
}) => {
  const starts = new Array(Math.ceil(rating)).fill(null);

  return (
    <div className={styles.course_details_block}>
      <span className="caption-text-bold text-primary-gray-500">{rating}</span>
      <div className={styles.rating_text}>
        {starts.map((_, idx) => (
          <img
            key={idx}
            width={16}
            height={16}
            src="/images/icons/star.svg"
            alt="star"
          />
        ))}
      </div>
      <span className="caption-text text-primary-gray-300">
        ({ratingCount})
      </span>
    </div>
  );
};

const Item = ({ course, type, inSearch }: IProps) => {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();

  const endTime = course.endTime;
  const startTime = course.startTime;

  let dateTime;

  if (startTime && endTime) {
    dateTime = formatDateTimeRange(startTime, endTime);
  }

  const onClick = () => {
    if (type === "class" || inSearch) {
      courseStore.getCohortById(course.id);
      return navigate(`/class/${course.id}`);
    } else {
      courseStore.getCourseById(course.id);
      navigate(`/course/${course.id}`);
    }
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.course_container}>
        <div className={styles.image_container}>
          <img
            className={styles.picture}
            src={`${env.IMAGE_API}${course.image}`}
            alt={course.title}
          />
          {course?.isOnline && (
            <div className="absolute top-2 sm:top-1 left-2 sm:left-1 caption-text-bold text-primary-paradise-pink-500 px-2.5 py-0.5 bg-primary-gray-50 rounded-md">
              ONLINE
            </div>
          )}
        </div>
        <div className={styles.course_details}>
          <div className="flex flex-col-reverse sm:flex-col gap-0.5 h-[5.5rem]">
            <div className="caption-text text-primary-purple-750">
              {course.provider.name}
            </div>
            <div
              className={
                styles.course_title +
                " body-large text-parimary-purple-850 line-clamp-2"
              }
            >
              {course.title}
            </div>
          </div>
          <div className={styles.course_details_items}>
            {type === "course" ? (
              <div className="hidden sm:block">
                <CourseReviews
                  rating={course.rating}
                  ratingCount={course.ratingCount}
                />
              </div>
            ) : null}
            {type === "class" && course.location ? (
              <div className={styles.course_details_block}>
                <img
                  width={16}
                  height={16}
                  src="/images/icons/location-gray.svg"
                  alt="date"
                />
                <span className="caption-text text-primary-gray-300">
                  {course.location}
                </span>
              </div>
            ) : null}
            {course.startTime && course.endTime ? (
              <div className={styles.course_details_block}>
                <img
                  width={16}
                  height={16}
                  src="/images/icons/date.svg"
                  alt="date"
                />
                <span className={styles.rating_count_text}>{dateTime}</span>
              </div>
            ) : null}
          </div>
          <div
            className={
              styles.course_price + " body-medium-bold text-primary-purple-500"
            }
          >
            {/* {userStore.currency} {course.price} */}
            {course?.price
              ? formatCurrency(course?.price, userStore.currency)
              : "FREE"}
          </div>
          <div className="hidden sm:block">
            <CourseDomains
              domains={course?.domains}
              difficulty={course?.difficulty}
            />
          </div>
        </div>
        {type === "class" ? (
          <div className={styles.course_footer}>
            {/* <div className={styles.course_provider}>
                                <img
                                    width={40}
                                    height={40}
                                    src={course.provider.logo}
                                    alt={course.provider.name}
                                    style={{ borderRadius: "50%" }}
                                />
                                <span>{course.provider.name}</span>
                            </div> */}
            <span
              className={
                styles.course_price +
                " body-medium-bold text-primary-purple-500"
              }
            >
              {course.price}
            </span>
          </div>
        ) : null}
      </div>

      <div className="flex sm:hidden justify-between p-1.5">
        <div>
          <CourseDomains
            domains={course?.domains}
            difficulty={course?.difficulty}
          />
        </div>
        <div className="mr-2.5">
          <CourseReviews
            rating={course.rating}
            ratingCount={course.ratingCount}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Item);
