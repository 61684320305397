import { observer } from "mobx-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  user: any;
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function ChoosePlan({ user, setUser, setStep }: IProps) {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const { t } = useTranslation();

  const [isRegisterClicked, setIsRegisterClicked] = useState<boolean>(false);

  const handleStudentAccount = () => {
    HubSpotsignupStore.setRoleChoice("Student");
    setStep(6);
  };

  const handleRegister = async (redirectionUrl: string, step: number) => {
    // If step=2 → Instructor
    if (step === 2) {
      HubSpotsignupStore.setRoleChoice("Instructor");
    }
    // If step=3 → Host
    else if (step === 3) {
      HubSpotsignupStore.setRoleChoice("Host");
    }
    navigate(redirectionUrl);
  };

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <span className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large">
        {t("choosePlan.heading")}
        </span>
        <span className="text-body-small sm:text-body-medium">
          {t("choosePlan.subheading")}
        </span>
      </div>

      <div className="flex flex-col gap-3.5 w-full">
        <button
          className="body-medium-bold flex justify-between items-center cursor-pointer px-4 py-5 sm:px-8 rounded-[14px] text-primary-purple-500 border-[1.5px] border-primary-purple-200 bg-primary-purple-50"
          onClick={handleStudentAccount}
          disabled={isRegisterClicked}
        >
          <div>
          <span>{t("choosePlan.studentTitle")}</span>
          <p className="body-small text-primary-paradise-pink-800">
          {t("choosePlan.studentSubtitle")}
          </p>
          </div>
          <img src="/images/icons/arrow-right-purple.svg" width={24} />
        </button>

        <button
          className="body-medium-bold flex justify-between items-center cursor-pointer px-4 py-5 sm:px-8 rounded-[14px] text-primary-paradise-pink-500 border-[1.5px] border-primary-paradise-pink-200 bg-primary-paradise-pink-50"
          onClick={() => handleRegister("/instructorUpgrade", 2)}
          disabled={isRegisterClicked}
        >
          <div>
          <span>{t("choosePlan.instructorTitle")}</span>
          <p className="body-small text-primary-paradise-pink-800">
          {t("choosePlan.instructorSubtitle")}
          </p>
          </div>
          <img src="/images/icons/arrow-right-pink.svg" width={24} />
        </button>

        <button
          className="body-medium-bold flex justify-between items-center cursor-pointer px-4 py-5 sm:px-8 rounded-[14px] text-primary-paradise-pink-500 border-[1.5px] border-primary-paradise-pink-200 bg-primary-paradise-pink-50"
          onClick={() => handleRegister("/hostUpgrade", 3)}
          disabled={isRegisterClicked}
        >
          <div>
          <span>{t("choosePlan.hostTitle")}</span>
          <p className="body-small text-primary-paradise-pink-800">
          {t("choosePlan.hostSubtitle")}
          </p>
          </div>
          <img src="/images/icons/arrow-right-pink.svg" width={24} />
        </button>
      </div>
    </>
  );
}

export default observer(ChoosePlan);
