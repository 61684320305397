import { observer } from "mobx-react";
import styles from "../../auth.module.css";
import { Button } from "src/components/shared";
import { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useStore } from "../../../../store";
import { useTranslation } from "react-i18next";


interface IProps {
  email: string;
  setStep: (step: number) => void;
}

function EmailInput({ email, setStep }: IProps) {
  const { userStore } = useStore();
  const { t } = useTranslation();


  const [values, setValues] = useState<string[]>(Array(5).fill(""));
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let value = e.target.value;
    if (value.length > 1) {
      value = value.slice(-1);
    }
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
    if (value.length === 1 && index < values.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && values[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
      const newValues = [...values];
      newValues[index - 1] = "";
      setValues(newValues);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData("text").trim();
    console.log(paste);
    if (paste.length === values.length) {
      const newValues = paste
        .split("")
        .map((char) => (/\d/.test(char) ? char : ""));
      setValues(newValues);
      inputRefs.current.forEach((input, index) => {
        if (input) {
          input.value = newValues[index];
        }
      });
      e.preventDefault();
      const nextInput = inputRefs.current[newValues.length - 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleChangeEmail = () => {
    userStore.logout();
    setStep(1);
  };

  const handleResendCode = async () => {
    // resend code logic
    const result = await userStore.resendVerificationCode();
    if (result) {
      toast.success(t("mailVerification.resendCodeSuccess") + "!");
      setValues(Array(5).fill(""));
      setFocusedIndex(0);
    } else {
      toast.success(t("mailVerification.failedresendCode") + "!");
    }
  };

  const handleNextStep = async () => {
    // code verfification logic
    setIsButtonClicked(true);
    const code = values.join("");
    const result = await userStore.verifyCode(code);
    if (result) {
      setStep(3);
    } else {
      setIsError(true);
      setValues(Array(5).fill(""));
      setFocusedIndex(0);
    }
    setIsButtonClicked(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col items-center text-center gap-2">
        <img src="/images/icons/verification-email.svg" width={120} />
        <h3 className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large text-primary-paradise-pink-500">
        {t("mailVerification.title")}
        </h3>
        <div className="text-body-small sm:text-body-medium">
        {t("mailVerification.description")} <br />
        <span className="font-body-small-bold">{email}</span>
        </div>
        {isError && (
          <div className="caption-text-bold px-6 py-1.5 text-tertiary-severe-600 rounded-full border border-tertiary-severe-100 bg-tertiary-severe-50">
            {t("mailVerification.invalidCode")}
            </div>
        )}
      </div>

      <div className="body-medium flex gap-1 items-center">
        {values.map((value, index) => (
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            key={index}
            type="number"
            maxLength={1}
            value={value}
            autoFocus={index === 0}
            onChange={(e) => handleChange(e, index)}
            onFocus={() => setFocusedIndex(index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={index === 0 ? handlePaste : undefined}
            className={`text-center rounded-xl w-[54px] h-[54px] outline-none ${
              focusedIndex === index
                ? "border-2 border-primary-paradise-pink-500 bg-primary-paradise-pink-50"
                : "border border-primary-gray-200"
            }`}
          />
        ))}
      </div>

      <div className={styles.social_buttons}>
        <Button
          type="secondary"
          text={t("mailVerification.changeEmailAddress")}
          onClick={handleChangeEmail}
          isDisabled={isButtonClicked}
        />
        <Button
          type="primary"
          text={t("mailVerification.continueButton")}
          isDisabled={values.some((value) => value === "") || isButtonClicked}
          onClick={handleNextStep}
        />
      </div>

      <div className="text-caption-text font-caption-text sm:text-body-small sm:font-body-small">
      <span>{t("mailVerification.notReceived")} </span>
      <span className={styles.privacy_link} onClick={handleResendCode}>
      {t("mailVerification.resendCode")}
      </span>
      </div>
    </>
  );
}

export default observer(EmailInput);
