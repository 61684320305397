import axios from "axios";
import { makeAutoObservable } from "mobx";
import env from "../config/env";
import { toast } from "react-toastify";

class HubSpotSignupStore {
  email: string = "";
  fullName: string = "";
  country: string = "";
  roles: "Student" | "Instructor" | "Host" | "" = "";
  interests: string[] = [];

  portalId = env.HUBSPOT_PORTALID;
  formGuid = env.HUBSPOT_FORMGUID;

  constructor() {
    makeAutoObservable(this);
  }

  setEmail(val: string) {
    this.email = val;
  }
  setFullName(val: string) {
    this.fullName = val;
  }
  setLocation(val: string) {
    this.country = val;
  }
  setRoleChoice(val: "Student" | "Instructor" | "Host") {
    this.roles = val;
  }

  setInterests(list: string[]) {
    this.interests = list;
  }

  async submitToHubspot() {
    try {
      const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formGuid}`;
      const joinedInterests = this.interests.join(";");

      const payload = {
        fields: [
          { name: "email", value: this.email },
          { name: "firstname", value: this.fullName },
          { name: "country", value: this.country },
          { name: "roles", value: this.roles },
          { name: "interests", value: joinedInterests },
        ],
        context: {
          pageUri: window.location.href,
          pageName: "Signup",
        },
      };

      const response = await axios.post(hubspotUrl, payload, {
        headers: { "Content-Type": "application/json" },
      });

      console.log("HubSpot form submission success", response.data);
    } catch (error) {
      console.error("Error sending to HubSpot:", error);
    }
  }

  async subscribeToHubspot(email: string, course: string) {
    try {
      const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formGuid}`;

      const payload = {
        fields: [
          { name: "email", value: email },
          { name: "course", value: course },
        ],
        context: {
          pageUri: window.location.href,
          pageName: "Subscribe For Classes Notifications",
        },
      };

      const response = await axios.post(hubspotUrl, payload, {
        headers: { "Content-Type": "application/json" },
      });

      console.log("HubSpot form submission success", response.data);
      toast.success("You will be notified when the course is available.");
    } catch (error) {
      console.error("Error sending to HubSpot:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  }
}

export const HubSpotsignupStore = new HubSpotSignupStore();
