import React, { KeyboardEventHandler, useRef, useState } from "react";

interface InputProps {
  label: string;
  required?: boolean;
  values: string[];
  setValues: (values: string[]) => void;
}

const MultiSelectInput: React.FC<InputProps> = ({
  label,
  required,
  values,
  setValues,
}) => {
  const [add, setAdd] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const handleDelete = (index: number) => {
    setValues(values.filter((_, i) => i !== index));
  };

  const handleAdd = () => {
    const value = prompt("Enter value");
    if (!value) return;
    setValues([...values, value]);
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValues([...values, inputValue]);
        setAdd(false);
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <div className="flex flex-col gap-0.5">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div className="body-small flex flex-wrap items-center gap-1 p-1 w-full min-h-12 text-primary-gray-800 outline outline-1 transition-all duration-300 outline-primary-gray-200 rounded-xl hover:outline-primary-paradise-pink-500">
        {values.map((value, index) => (
          <div
            key={index}
            className="flex items-center justify-center gap-1.5 px-3 py-2 bg-primary-paradise-pink-100 rounded-lg"
          >
            <span className="caption-text text-primary-paradise-pink-500">
              {value}
            </span>
            <img
              src="/images/icons/close-pink.svg"
              className="cursor-pointer"
              onClick={() => handleDelete(index)}
            />
          </div>
        ))}
        {add && (
          <input
            autoFocus
            type="text"
            className="outline-none w-fit px-3 py-2"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        )}
        <div
          className="bg-primary-paradise-pink-100 border border-primary-paradise-pink-300 rounded-full p-[5px] cursor-pointer"
          onClick={() => setAdd(true)}
        >
          <img src="/images/icons/plus-primary.svg" width={14} />
        </div>
      </div>
    </div>
  );
};

export default MultiSelectInput;
