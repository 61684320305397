import React from "react";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { Button, Select } from "src/components/shared";
import styles from "./popup.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  sessions: any[];
  courses: any[];
}

const Popup: React.FC<IProps> = ({ isOpen, sessions, courses }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = React.useState("");
  const [selectedSession, setSelectedSession] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");

  const options = [
    { value: "1", label: t("AddClassPopup.createOwnCourse") },
    { value: "2", label: t("AddClassPopup.createClassFromPrevious") },
    { value: "3", label: t("AddClassPopup.createClassFromExistingCourse") },
  ];

  const sessionsList = sessions.map((session) => ({
    value: session.id,
    label: session.title,
  }));

  const coursesList = courses.map((course) => ({
    value: course.id,
    label: course.title,
  }));

  const handleCancel = () => {
    navigate("/teaching");
  };

  const handleNext = () => {
    switch (selectedOption) {
      case t("AddClassPopup.createOwnCourse"):
        navigate("/newCourse");
        break;
      case t("AddClassPopup.createClassFromPrevious"):
        const session: any = sessionsList.find(
          (s: any) => s.label === selectedSession
        );
        navigate(`/add-class/new/${session.value}`);
        break;
      case t("AddClassPopup.createClassFromExistingCourse"):
        const course: any = coursesList.find(
          (c: any) => c.label === selectedCourse
        );
        navigate(`/add-class/new/${course.value}`);
        break;
      default:
        break;
    }
  };

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className={styles.content}>
        <h1>{t("AddClassPopup.title")}</h1>

        <div className="field">
          <label htmlFor="option">{t("AddClassPopup.pleaseSelect")}</label>
          <div className="input-field">
            <div className="input">
              <Select
                label={t("AddClassPopup.noOptionSelected")}
                fullWidth
                options={options}
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
            </div>
          </div>
        </div>

        {selectedOption === t("AddClassPopup.createClassFromPrevious") && (
          <div className="field">
            <h2>{t("AddClassPopup.previousClass")}</h2>
            <label htmlFor="option">
              {t("AddClassPopup.pleaseSelectPreviouslyTaught")}
            </label>
            <div className="input-field">
              <div className="input">
                <Select
                  label={t("AddClassPopup.noClassSelected")}
                  fullWidth
                  options={sessionsList}
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}

        {selectedOption ===
          t("AddClassPopup.createClassFromExistingCourse") && (
          <div className="field">
            <h2>{t("AddClassPopup.previousCourse")}</h2>
            <label htmlFor="option">
              {t("AddClassPopup.pleaseSelectExistingCourse")}
            </label>
            <div className="input-field">
              <div className="input">
                <Select
                  label={t("AddClassPopup.noCourseSelected")}
                  fullWidth
                  options={coursesList}
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.button_container}>
        <Button
          type="secondary"
          text={t("AddClassPopup.cancel")}
          onClick={handleCancel}
        />
        <Button
          type="primary"
          text={t("AddClassPopup.next")}
          onClick={handleNext}
          isDisabled={
            !(
              selectedOption === t("AddClassPopup.createOwnCourse") ||
              (selectedOption === t("AddClassPopup.createClassFromPrevious") &&
                selectedSession !== "") ||
              (selectedOption ===
                t("AddClassPopup.createClassFromExistingCourse") &&
                selectedCourse !== "")
            )
          }
        />
      </div>
    </Modal>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "520px                                             ",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "8px",
    border: "1px solid #ccc",
    transform: "translate(-50%, -50%)",
  },
};

export default observer(Popup);
