import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import "../index.css";

interface DateRangeProps {
  startDate: Date | undefined;
  setStartDate: (date: Date | undefined) => void;
  endDate: Date | undefined;
  setEndDate: (date: Date | undefined) => void;
}

const DateRange: React.FC<DateRangeProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: DateRangeProps) => {
  const CustomInput = React.forwardRef<any, any>(
    ({ value, onClick, placeholder }, ref) => (
      <input
        className="custom-input"
        onClick={onClick}
        ref={ref}
        placeholder={placeholder}
        value={value}
        onChange={() => {}}
      />
    )
  );
  const { t } = useTranslation();

  return (
    <div className="date-range-picker">
      <DatePicker
        selected={startDate}
        onChange={(date: Date | null) => setStartDate(date ?? undefined)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText={t("newClassroomPage.startDate")}
        dateFormat="dd-MM-yyyy"
        customInput={<CustomInput placeholder="Start date" />}
      />
      <span className="arrow">→</span>
      <DatePicker
        selected={endDate}
        onChange={(date: Date | null) => setEndDate(date ?? undefined)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText={t("newClassroomPage.endDate")}
        dateFormat="dd-MM-yyyy"
        customInput={<CustomInput placeholder="End date" />}
      />
      <img src="/images/date.png" alt="" />
    </div>
  );
};

export default DateRange;
