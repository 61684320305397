import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { Navbar } from "src/components/shared";
import Container from "src/components/shared/Container";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { userStore } = useStore();
  const navigate = useNavigate();

  const sendForgotPasswordRequest = async () => {
    setIsButtonDisabled(true); 
  
    try {
      const res = await userStore.forgotPassword(email);
  
      if (res) {
        // toast.success(res);
          navigate("/mail-verification", { state: { email } });
      } else {
        toast.error(t("forgotPassword.resetFailed"));
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message ||`${t("forgotPassword.resetFailed")} (${email})`);
      // toast.error("Email not found/Error sending reset link");
      setIsButtonDisabled(false); 
    }
  };
  




  const handleEmailChange = (value: string) => {
    setEmail(value);
  
    if (!value) {
      setEmailError(t("forgotPassword.emailRequiredError"));
      setIsButtonDisabled(true);
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
      setEmailError(t("forgotPassword.invalidEmailFormat"));
      setIsButtonDisabled(true);
    } else if (/[^a-zA-Z0-9@._-]/.test(value)) {
      setEmailError(t("forgotPassword.invalidEmailCharacters"));
      setIsButtonDisabled(true);
    } else if (/\.\./.test(value)) {
      setEmailError(t("forgotPassword.consecutiveDots"));
      setIsButtonDisabled(true);
    } else if (/^\./.test(value) || /\.$/.test(value)) {
      setEmailError(t("forgotPassword.startEndDot"));
      setIsButtonDisabled(true);
    } else if (!/^.+@.+\..+$/.test(value)) {
      setEmailError(t("forgotPassword.domainMissing"));
      setIsButtonDisabled(true);
    } else if (/\s/.test(value)) {
      setEmailError(t("forgotPassword.containsSpaces"));
      setIsButtonDisabled(true);
    } else {
      setEmailError(""); 
      setIsButtonDisabled(false);
    }
  };
  

  useEffect(() => {
  }, [email]);

  return (
    <>
      <Navbar />
      <ToastContainer theme="dark" />
      <Container>

      <div className="flex items-center justify-center py-10 px-4">
      <div className="flex flex-col items-center max-w-md sm:max-w-lg lg:max-w-lg">
      {/* Stepper */}
          <ol className="flex justify-between items-center w-full mb-6 px-1">
            <li className="flex-1 h-[5px] mx-1 bg-[#E83C70] rounded-full"></li>
            <li className="flex-1 h-[5px] mx-1 bg-pink-100 rounded-full"></li>
            <li className="flex-1 h-[5px] mx-1 bg-pink-100 rounded-full"></li>
          </ol>

          <div className="bg-white rounded-xl border border-[#0000000D] w-full max-w-[480px] pt-[25px] pr-5 pb-[25px] pl-5 sm:pr-[40px] sm:pl-[40px] ">
          <h4 className="text-[22px] leading-8 md:text-[22px] sm:text-[16px] sm:leading-6 font-semibold mb-2 max-w-[321px] text-[#1B0209]">
          {t("forgotPassword.title")}
          </h4>
            <p className="text-[16px] leading-6 md:text-[16px] sm:text-[14px] sm:leading-6 font-normal max-w-[321px] font-inter text-[#1B0209] mb-6 items-center">
            {t("forgotPassword.description")}
            </p>
            

            <label
              htmlFor="email"
              className="mb-2 text-xs font-medium text-gray-900 leading-6"
            >
            {t("forgotPassword.emailAddressLabel")}{" "}
            <span className="text-[#D22121]">*</span>
            </label>

            <div className="relative max-w-[322px] ">
            <input
                id="email"
                type="email"
                value={email}
                required
                onChange={(e) => handleEmailChange(e.target.value)}
                placeholder={t("forgotPassword.emailAddressPlaceholder")}
                className={`hover:border-[#E83C70] focus:border-[#E83C70] focus:bg-[#FFFFFF] px-3 w-full h-[48px] text-sm font-normal border rounded-xl text-start inline-flex items-start leading-6 text-primary-gray-800 ${
                  emailError ? "border-[#D22121]" : "border-gray-200"
                } bg-white text-gray-900`}
              />
              {emailError && (
                <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#D22121]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="red"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="white"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                    />
                  </svg>
                </span>
              )}
            </div>

            {emailError && (
              <span className="text-[#D22121] text-xs font-medium mb-3 leading-6 block pt-2">
                {emailError}
              </span>
            )}

            <button
              type="button"
              onClick={() => sendForgotPasswordRequest()}
              disabled={isButtonDisabled}
              className={`max-w-[322px] text-[#FFFAFB] mt-[25px] w-full h-[50px] justify-center bg-[#E83C70] border-2 border-transparent hover:border-[#E83C70] hover:text-[#E83C70] hover:bg-white focus:ring-4 focus:outline-none focus:ring-[#FFFFFF]/50 font-semibold rounded-xl leading-6 text-sm text-center inline-flex items-center dark:focus:ring-[#FFFFFF]/55 ${
                      
                        isButtonDisabled
                          ? "bg-[#E83C70] opacity-50 cursor-not-allowed"
                          : "bg-[#E83C70] hover:bg-pink-600"
                      }`}
            >
                {t("forgotPassword.continueButton")}
                </button>
          </div>
        </div>
      </div>
      </Container>
    </>
  );
}

export default observer(ForgotPassword);
