import React, { useEffect, useState } from "react";

import styles from "./popupModal.module.css";
import Button from "../Button";
import Modal from "react-modal";

interface IProps {
  header: string;
  text?: string;
  isOpen: boolean;
  actionMessage?: string;
  abortMessage: string;
  close: () => void;
  onClick?: () => void;
}

const PopupModal: React.FC<IProps> = ({
  header,
  text,
  isOpen,
  actionMessage,
  abortMessage,
  close,
  onClick,
}) => {
  const [modalWidth, setModalWidth] = useState("473px");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setModalWidth("calc(100% - 40px)");
      } else {
        setModalWidth("473px");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    content: {
      padding: "0",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      background: "#fff",
      transform: "translate(-50%, -50%)",
      boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
      borderRadius: "12px",
      border: "none",
      width: modalWidth,
    },
  };

  return (
    <div className={styles.popup_container}>
      <Modal isOpen={isOpen} onRequestClose={close} style={customStyles}>
        <div className="body-large flex justify-between items-center px-6 py-4 border-b border-primary-gray-200 bg-primary-gray-50">
          <h3>{header}</h3>
          <img
            className="cursor-pointer"
            src="/images/icons/close-black.svg"
            alt=""
            onClick={close}
          />
        </div>
        <div className="px-6 py-3.5">
          <p className="body-medium mb-4">{text}</p>
        </div>
        <div className="flex flex-wrap justify-end gap-2.5 px-6 py-2.5 bg-primary-purple-50">
          <Button
            type="outline"
            text={abortMessage}
            onClick={close}
            width="fit-content"
          />
          {onClick && (
            <Button
              type="primary_dark"
              text={actionMessage}
              onClick={onClick}
              width="fit-content"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PopupModal;
