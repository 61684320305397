import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  Button,
  CourseComponents,
  Popup,
  BubbleMessage,
} from "../../../../components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { toast } from "react-toastify";

interface IProps {
  type: "course" | "class";
}

function CourseHeader({ type }: IProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();
  const { t } = useTranslation();

  const sessions: any[] = courseStore.searchedCourses;

  const [active, setActive] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState<boolean>(false);
  const [isAddedToFavorites, setIsAddedToFavorites] = useState<boolean>(false);
  const [isWishListClicked, setIsWishListClicked] = useState<boolean>(false);
  const [showBubble, setShowBubble] = useState<boolean>(false);
  const [bubbleClicked, setBubbleClicked] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isAddedToFavorites && sessions.length === 0) {
        setShowBubble(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [isAddedToFavorites]);

  let course: any = courseStore.courseDetails;
  const image =
    type === "class" ? course?.SessionImage?.name : course?.image?.name;
  const startTime = course?.start
    ? formatDate(new Date(course.start).toISOString().split("T")[0])
    : null;

  const endTime = course?.end
    ? formatDate(new Date(course.end).toISOString().split("T")[0])
    : null;

  const mostPositiveReview =
    course?.testimonials?.length > 0 ? course?.testimonials[0] : null;

  function formatDate(dateString: string): string {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (userStore.user) {
      let user = userStore.user;
      let userFavoritesList: any = [];
      if (user.favoriteCourse && user.favoriteCourse.length > 0) {
        userFavoritesList.push(...user.favoriteCourse);
      }
      if (user.favoriteSession && user.favoriteSession.length > 0) {
        userFavoritesList.push(...user.favoriteSession);
      }
      let userFavoritesSessionIds = userFavoritesList.map(
        (userFavorite: any) => {
          return userFavorite.sessionId
            ? userFavorite.sessionId.toString()
            : userFavorite.courseId.toString();
        }
      );

      if (userFavoritesSessionIds && userFavoritesSessionIds.length > 0) {
        if (userFavoritesSessionIds.includes(course?.id)) {
          setIsAddedToFavorites(true);
          setShowBubble(false);
        }
      }
    }
  }, [userStore.user, course]);

  const lookForSessions = async () => {
    if (type === "course") {
      onClick(8, "upcoming-cohorts");
    } else {
      courseStore.selectCourse(course.id);

      if (userStore.user) {
        //await courseStore.lookForSessions(course.id);
        navigate("/search");
      } else {
        setIsOpen(true);
      }
    }
  };

  const onClick = (index: number, link: string) => {
    setActive(index);
    const element = document.getElementById(link);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const addToFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      let response: any;
      type === "class"
        ? (response = await courseStore.addSessionToFavorites(
            course?.id,
            userStore.user.id
          ))
        : (response = await courseStore.addCourseToFavorites(
            course?.id,
            userStore.user.id
          ));
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(true);
        toast.success(
          `You’ve successfully added ${course.title} to your Wishlist.`
        );
        setShowBubble(false);
        setIsWishListClicked(false);
      });
    } else {
      setIsWishListClicked(false);
      setIsOpen(true);
    }
  };

  const deleteFromFavorites = async () => {
    setIsWishListClicked(true);
    if (userStore.user) {
      let favoriteObject =
        type === "class"
          ? userStore.user.favoriteSession.find(
              (favoriteSession: any) =>
                favoriteSession.sessionId.toString() === course.id.toString()
            )
          : userStore.user.favoriteCourse.find(
              (favoriteCourse: any) =>
                favoriteCourse.courseId.toString() === course.id.toString()
            );
      type === "class"
        ? await courseStore.deleteSessionFromFavorites(favoriteObject.id)
        : await courseStore.deleteCourseFromFavorites(favoriteObject.id);
      await userStore.getUser().then(() => {
        setIsAddedToFavorites(false);
        setIsWishListClicked(false);
        if (sessions.length === 0) {
          setShowBubble(true);
          setBubbleClicked(false);
        }
      });
    } else {
      setIsWishListClicked(false);
      setIsOpen(true);
    }
  };

  const formatReviewDate = (date: string | undefined) => {
    if (!date) return "";
    return format(new Date(date), "dd MMM, yyyy");
  };

  const viewClass = () => {
    if (sessions.length) {
      navigate(`/class/${sessions[0].id}`);
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-3">
        {course?.studentsCount > 12 && (
          <div
            className="flex justify-center items-center gap-2.5 p-5 border border-primary-paradise-pink-200 rounded-2xl"
            style={{
              background:
                "linear-gradient(90deg, #FFF3F5 0%, #FFFFFF 50%, #FFF3F5 100%)",
            }}
          >
            <img src="/images/icons/rounded-star-pink.svg" />
            <p className="body-small-bold text-primary-paradise-pink-750">
              Popular Right Now
            </p>
          </div>
        )}
        <img
          className="sm:hidden rounded-xl object-cover w-full"
          src={
            image ? `${env.IMAGE_API}${image}` : "/images/course-default.png"
          }
          alt={course?.title}
        />
        <h1 className="text-body-large font-body-large-bold sm:text-heading-medium sm:font-heading-medium text-primary-paradise-pink-800">
          {course?.title}
        </h1>
        {course?.domains?.length > 0 && (
          <div className="flex flex-wrap items-center gap-2">
            {course?.domains?.map((domain: any, index: number) => (
              <span
                key={index}
                className="caption-text-bold px-4 py-1 text-primary-paradise-pink-500 rounded-lg bg-primary-paradise-pink-100"
              >
                {domain?.name ?? domain}
              </span>
            ))}
          </div>
        )}
        <p className="text-body-small sm:text-body-medium">
          {course?.description}
        </p>
      </div>
      <div className="flex gap-3 items-center">
        <div className="flex items-center gap-2.5">
          <span className="caption-text-bold text-primary-gray-500">
            {course?.rating || 5}
          </span>
          <div className="flex gap-1">
            {Array.from(
              { length: Math.floor(course?.rating || 5) },
              (_, index) => (
                <img
                  key={index}
                  width={16}
                  height={16}
                  alt="Star Icon"
                  src="/images/icons/star-yellow.svg"
                />
              )
            )}
            {Array.from(
              { length: 5 - Math.floor(course?.rating || 5) },
              (_, index) => (
                <img
                  key={index}
                  width={16}
                  height={16}
                  alt="Star Icon"
                  src="/images/icons/star-gray.svg"
                />
              )
            )}
          </div>
          <span className="caption-text text-primary-gray-300">
            ({course?.ratingsCount || 5})
          </span>
        </div>
        {!(
          type === "course" &&
          (course?.courseCreator === "wecodeland" ||
            course?.courseCreator === "WeCode")
        ) &&
          !(
            type !== "course" &&
            (course?.sessionCourse?.courseCreator === "wecodeland" ||
              course?.sessionCourse?.courseCreator === "WeCode")
          ) && (
            <span className="flex items-center gap-3">
              <img src="/images/icons/point.svg" />
              <span className="text-body-small text-primary-paradise-pink-800">
                {t("messages.createdBy")}{" "}
                {type === "course"
                  ? course?.courseCreator
                  : course?.sessionCourse?.courseCreator}
              </span>
            </span>
          )}
      </div>

      <div className="flex gap-3">
        <div className="w-full sm:w-[143px]">
          <Button
            isDisabled={sessions.length === 0}
            type="primary"
            text="View Class"
            onClick={viewClass}
          />
        </div>
        <div className="relative">
          <div
            className={`transition-opacity duration-200 ease-in-out ${
              showBubble && !bubbleClicked
                ? "opacity-100 cursor-pointer"
                : "opacity-0 z-1"
            }`}
            onClick={() => {
              setBubbleClicked(true);
              setShowBubble(false);
            }}
          >
            <div className="block sm:hidden">
              <BubbleMessage
                text="No classes going on? Add it to your Wishlist for later!"
                tailPosition="bottom-right"
              />
            </div>
            <div className="hidden sm:block">
              <BubbleMessage
                text="No classes going on? Add it to your Wishlist for later!"
                tailPosition="bottom"
              />
            </div>
          </div>

          <Button
            type={isAddedToFavorites ? "wishlist_on" : "wishlist_off"}
            width="fit-content"
            icon={
              isAddedToFavorites
                ? "/images/icons/heart-white.svg"
                : "/images/icons/heart.svg"
            }
            onClick={() =>
              isAddedToFavorites ? deleteFromFavorites() : addToFavorites()
            }
            customStyle={{ padding: "16px" }}
            isWishListClicked={isWishListClicked}
          />
        </div>
      </div>

      <div className="flex flex-col gap-4 p-5 border border-primary-purple-100 bg-primary-purple-50 rounded-2xl">
        <h3 className="body-small-bold text-primary-purple-500">
          Most Positive Review
        </h3>
        <div className="flex justify-between">
          {mostPositiveReview && (
            <div className="flex gap-3 items-center">
              <img
                className="object-cover rounded-full size-[46px]"
                src={
                  mostPositiveReview?.photo?.container?.name
                    ? `${env.IMAGE_API}${mostPositiveReview?.photo?.container?.name}`
                    : "/images/default-user.svg"
                }
              />
              <div className="flex flex-col gap-1">
                <h4 className="body-medium text-primary-gray-800">
                  {mostPositiveReview?.author}
                </h4>
                <div className="flex items-center gap-1">
                  <span className="caption-text text-primary-gray-500">
                    5.0
                  </span>
                  {Array.from({ length: Math.floor(5) }, (_, index) => (
                    <img
                      key={index}
                      width={16}
                      height={16}
                      alt="Star Icon"
                      src="/images/icons/star-yellow.svg"
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          {/* <span className="caption-text text-primary-gray-300">
              {formatReviewDate(mostPositiveReview?.createdAt)}
            </span> */}
        </div>
        <div className="flex flex-col gap-1.5 text-primary-gray-800">
          {mostPositiveReview?.title && (
            <h4 className="body-medium-bold">{mostPositiveReview?.title}</h4>
          )}
          <p className="body-small">{mostPositiveReview?.content}</p>
        </div>
      </div>

      {isOpen && (
        <Popup
          type={type}
          isOpen={isOpen}
          course={course.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
      {isScheduleOpen && (
        <Modal
          isOpen={isScheduleOpen}
          onRequestClose={() => setIsScheduleOpen(false)}
          style={customStyles}
        >
          <CourseComponents.CourseSchedule />
          <Button
            text="Ok"
            type="primary"
            width="100px"
            customStyle={{ marginLeft: "auto" }}
            onClick={() => setIsScheduleOpen(false)}
          />
        </Modal>
      )}
    </div>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "80%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "8px",
    border: "1px solid #ccc",
    transform: "translate(-50%, -50%)",
  },
};

export default observer(CourseHeader);
