import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

import { Button } from "../../../../components";

interface IProps {
  setStep: (step: number) => void;
}

function Info({ setStep }: IProps) {
  const navigate = useNavigate();

  const handleNextStep = async () => {
    setStep(2);
  };

  const handleBack = () => {
    navigate("/register");
  };

  return (
    <div className="flex flex-col gap-9 p-4 sm:p-8 bg-primary-purple-50 border border-primary-purple-200 rounded-3xl sm:rounded-2xl">
      <div className="flex flex-col gap-2.5 sm:gap-4">
        <h3 className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large">
          Instructor Verification Process
        </h3>
        <p className="body-medium">
          To set you up as an instructor, we just need a few simple details
          about you. This helps us ensure quality for our learners and
          confidence for you as a trusted instructor.
        </p>
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          type="pale"
          text="Back"
          width="fit-content"
          onClick={handleBack}
        />
        <Button
          type="primary_dark"
          text="Got it, Let's start!"
          width="fit-content"
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
}

export default observer(Info);
