import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../../store";
import {
  Navbar,
  Loading,
  UploadImage,
  Input,
  TextEditor,
  Select as RSelect,
  MultiSelectInput,
  Button,
} from "src/components/shared";
import Select from "react-select";
import "./styles.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import RichTextEditor from "src/components/shared/Input/RichTextEditor";

export default function NewCourse() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("/login");
      }
    });
  }, []);

  const user = userStore.user;

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [courseContents, setCourseContents] = useState([
    { step: 1, title: "", content: "", expanded: true },
  ]);
  const [forWho, setForWho] = useState("");
  const [objectifs, setObjectifs] = useState("");
  const [objectifsEditorState, setObjectifsEditorState] = useState(
    EditorState.createEmpty()
  );
  const [objectivesImage, setObjectivesImage] = useState<File | null>(null);
  const [perspectives, setPerspectives] = useState("");
  const [perspectivesEditorState, setPerspectivesEditorState] = useState(
    EditorState.createEmpty()
  );
  const [perspectivesImage, setPerspectivesImage] = useState<File | null>(null);
  const [price, setPrice] = useState("");
  const [level, setLevel] = useState("Beginner");
  const [createdBy, setCreatedBy] = useState("");
  const [skills, setSkills] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [domains, setDomains] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState<
    { value: string; label: string }[]
  >([]);

  const [currency, setCurrency] = useState("USD");
  const [language, setLanguage] = useState("English");
  const [courseAttachment, setCourseAttachment] = useState<any | null>(null);

  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const summaryRef = useRef<HTMLTextAreaElement>(null);

  const [courseTestimonials, setCourseTestimonials] = useState([
    {
      author: "",
      content: "",
      photo: {
        preview: "images/default-user.jfif",
        data: {},
        container: {},
      },
    },
  ]);

  const courseLevels = [
    { value: 1, label: "Beginner" },
    { value: 2, label: "Intermediate" },
    { value: 3, label: "Advanced" },
  ];

  const currencyOptions = [
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "CAD", label: "CAD" },
    { value: "TND", label: "TND" },
  ];

  const languageOptions = [
    { value: "English", label: "English" },
    { value: "French", label: "French" },
  ];

  useEffect(() => {
    setLoading(true);
    Promise.all([courseStore.getAllDomains(), courseStore.getAllCategories()])
      .then(([domainsResponse, categoriesResponse]) => {
        if (domainsResponse !== null) {
          const domains = domainsResponse.map((domain: any) => ({
            value: domain.id,
            label: domain.name,
          }));
          setDomains(domains);
        }

        if (categoriesResponse !== null) {
          console.log(categoriesResponse);
          const categories = categoriesResponse.map((category: any) => ({
            value: category.id,
            label: category.name,
          }));
          setCategories(categories);
          setCategory(categories[0].value);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleAddContent = () => {
    setCourseContents((prev) => [
      ...prev,
      { step: prev.length + 1, title: "", content: "", expanded: true },
    ]);
  };

  const handleRemoveContent = (index: number) => {
    if (courseContents.length > 1) {
      setCourseContents((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleContentChange = (
    index: number,
    field: "title" | "content",
    value: string
  ) => {
    setCourseContents((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      console.log(value);
      return updated;
    });
  };

  const toggleExpandCollapse = (index: number) => {
    setCourseContents((prev) => {
      const updated = [...prev];
      updated[index].expanded = !updated[index].expanded;
      return updated;
    });
  };

  const links = [
    { path: "/mycourses", label: t("newCourse.breadcrumbs.myCourses") },
  ];
  const handleSave = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const descriptionLength = description.trim().length;
    const summaryLength = forWho.trim().length;

    if (descriptionLength < 300) {
      descriptionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoading(false);
      return;
    }

    if (summaryLength < 400) {
      summaryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setLoading(false);
      return;
    }

    const course = {
      title: title,
      description: description,
      image: image,
      difficulty: level,
      courseCreator: createdBy,
      forWho: forWho,
      price: price,
      syllabus: courseContents.map((content: any) => ({
        title: content.title,
        content: content.content,
      })),
      prerequisite: skills,
      objectifs: {
        content:
          objectifs.length > 0
            ? JSON.parse(objectifs)
            : { blocks: [], entityMap: {} },
        image: objectivesImage,
      },
      perspectives: {
        content:
          perspectives.length > 0
            ? JSON.parse(perspectives)
            : { blocks: [], entityMap: {} },
        image: perspectivesImage,
      },
      domains: selectedDomains.map((domain: any) => {
        return { id: domain.value, name: domain.label };
      }),
      categoryId: categories.find((cat: any) => cat.label === category)?.value,
      currency: currency,
      language: language,
      testimonials: courseTestimonials,
      file:
        courseAttachment !== null
          ? courseAttachment
          : { name: "", originalFileName: "" },
      isOldSyllabus: false,
    };

    if (image) {
      await userStore.uploadPicture(image).then((response) => {
        course.image = response;
      });
    }

    if (perspectivesImage) {
      await userStore.uploadPicture(perspectivesImage).then((response) => {
        course.perspectives.image = response;
      });
    }

    if (objectivesImage) {
      await userStore.uploadPicture(objectivesImage).then((response) => {
        course.objectifs.image = response;
      });
    }

    await courseStore
      .newCourse(course)
      .then((response) => {
        console.log(course);
        console.log(response);
        setLoading(false);
        navigate("/mycourses");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // const handleSyllabusEditorChange = (newEditorState: EditorState) => {
  //   setSyllabusEditorState(newEditorState);
  //   const rawContentState = convertToRaw(newEditorState.getCurrentContent());
  //   setSyllabus(JSON.stringify(rawContentState));
  // };

  const handleObjectifsEditorChange = (newEditorState: EditorState) => {
    setObjectifsEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setObjectifs(JSON.stringify(rawContentState));
  };

  const handlePerspectivesEditorChange = (newEditorState: EditorState) => {
    setPerspectivesEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    setPerspectives(JSON.stringify(rawContentState));
  };

  const updateTestimonialField = (index: any, field: any, value: any) => {
    const updatedTestimonials = [...courseTestimonials];
    updatedTestimonials[index] = {
      ...updatedTestimonials[index],
      [field]: value,
    };
    setCourseTestimonials(updatedTestimonials);
  };

  const photoChangeHandlerTestimonial = async (index: any, e: any) => {
    const updatedTestimonials = [...courseTestimonials];
    const file = e.target.files[0];
    updatedTestimonials[index].photo = {
      preview: URL.createObjectURL(file),
      data: file,
      container: {},
    };
    setCourseTestimonials(updatedTestimonials);
    const formData = new FormData();
    formData.append("file", file);
    try {
      userStore.uploadPicture(file).then((response) => {
        updatedTestimonials[index].photo.container = response;
        setCourseTestimonials(updatedTestimonials);
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Add new testimonial
  const addNewTestimonial = () => {
    setCourseTestimonials([
      ...courseTestimonials,
      {
        author: "",
        content: "",
        photo: {
          preview: "images/default-user.jfif",
          data: {},
          container: {},
        },
      },
    ]);
  };

  // Remove testimonial
  const removeTestimonial = (index: any) => {
    if (courseTestimonials.length > 1) {
      const updatedTestimonials = [...courseTestimonials];
      updatedTestimonials.splice(index, 1);
      setCourseTestimonials(updatedTestimonials);
    }
  };

  const updateCourseAttachment = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      userStore.uploadFile(file).then((response) => {
        setCourseAttachment(response);
      });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const setHtml = (index: number, value: any) => {
    handleContentChange(index, "content", value);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />

      <form className="new-course" onSubmit={handleSave}>
        <div className="links">
          {links.map((link, index) => (
            <div className="link" key={index}>
              <Link to={link.path} className="label">
                {link.label}
              </Link>
              <div className="arrow">&gt;</div>
            </div>
          ))}
          <div className="active label">
            {t("newCourse.breadcrumbs.addNewCourse")}
          </div>
        </div>

        <h1>{t("newCourse.heading")}</h1>

        <div className="inputs">
          <div className="left-column">
            <div className="field">
              <label htmlFor="picture">{t("newCourse.bannerLabel")}</label>
              <div className="input-field">
                <UploadImage image={image} setImage={setImage} width="80%" />
              </div>
            </div>
          </div>

          <div className="right-column">
            <div className="field">
              <label htmlFor="title">{t("newCourse.titleLabel")}</label>
              <div className="input-field">
                <div className="input">
                  <Input
                    type="text"
                    label={t("newCourse.titlePlaceholder")}
                    required
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="field">
              <label htmlFor="description">
                <div className="w-full flex justify-between items-center">
                  <span>{t("newCourse.descriptionLabel")}</span>
                  {description.length < 300 && (
                    <small className="text-red-500">
                      {t("newCourse.descriptionHelper")}{" "}
                      {`(${300 - description.length} ${t(
                        "newCourse.descriptionCounter"
                      )})`}
                    </small>
                  )}
                </div>
              </label>
              <div className="input-field">
                <textarea
                  className="bio resize-none"
                  required
                  id="description"
                  placeholder={t("newCourse.descriptionPlaceholder") || ""}
                  value={description}
                  ref={descriptionRef}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label htmlFor="description">
            <div className=" w-full flex justify-between items-center">
              <span>{t("newCourse.summaryLabel")}</span>
              {forWho.length < 400 && (
                <small className="text-red-500 font-inter font-normal">
                  {t("newCourse.summaryHelper")}{" "}
                  {`(${400 - forWho.length} ${t(
                    "newCourse.descriptionCounter"
                  )})`}
                </small>
              )}
            </div>
          </label>
          <div className="input-field">
            <textarea
              className="bio resize-none"
              required
              id="summary"
              placeholder={t("newCourse.summaryPlaceholder") || ""}
              value={forWho}
              ref={summaryRef}
              onChange={(e) => setForWho(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="syllabus">{t("newCourse.syllabusLabel")}</label>
          {courseContents.map((content, index) => (
            <div key={index} className="content-item mt-2">
              <div className="w-full flex justify-start items-center space-x-2">
                <button
                  type="button"
                  onClick={() => toggleExpandCollapse(index)}
                  style={{
                    color: "var(--Primary-ParadisePink-500)",
                    border: "1px solid",
                    borderColor: "var(--Primary-Gray-200)",
                    borderRadius: "12px",
                    width: "50px",
                    height: "50px",
                    fontSize: "40px",
                  }}
                  className="flex justify-center items-center"
                >
                  {content.expanded ? "-" : "+"}
                </button>
                <input
                  type="text"
                  name="content title"
                  placeholder="Content Title"
                  value={content.title}
                  onChange={(e) =>
                    handleContentChange(index, "title", e.target.value)
                  }
                  style={{
                    padding: "12px 16px",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: "var(--black)",
                    outline: "1px solid var(--Primary-Gray-200)",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                />
                {courseContents.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveContent(index)}
                    style={{
                      color: "var(--Primary-ParadisePink-500)",
                      border: "1px solid",
                      borderColor: "var(--Primary-Gray-200)",
                      borderRadius: "12px",
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    X
                  </button>
                )}
              </div>

              {/* {content.expanded && (
                <div className="input-field">
                  <textarea
                    className="bio resize-none"
                    required
                    id="summary"
                    placeholder="Content Description"
                    ref={summaryRef}
                    value={content.content}
                    onChange={(e) =>
                      handleContentChange(index, "content", e.target.value)
                    }
                  />
                </div>
              )} */}

              {content.expanded && (
                <div className="input-field">
                  <RichTextEditor
                    html={content.content}
                    setHtml={(value) => setHtml(index, value)}
                  />
                </div>
              )}
            </div>
          ))}
          <div className="w-full flex justify-center items-center mt-2">
            <Button
              text="add"
              onClick={handleAddContent}
              type="primary"
              width="150px"
              preventDefault={true}
            />
          </div>
        </div>

        <div className="w-full flex flex-col justify-start items-start">
          <div className="w-full">
            <label htmlFor="objectifs">{t("newCourse.objectivesLabel")}</label>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-start items-center space-y-12 md:space-y-0 md:space-x-8">
            <div className="w-full">
              <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                <Editor
                  editorState={objectifsEditorState}
                  onEditorStateChange={handleObjectifsEditorChange}
                />
              </div>
            </div>
            <div className="w-full md:w-[450px] input-field">
              <UploadImage
                image={objectivesImage}
                setImage={setObjectivesImage}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-start items-start">
          <div className="w-full">
            <label htmlFor="perspectives">
              {t("newCourse.perspectivesLabel")}
            </label>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-start items-center space-y-12 md:space-y-0 md:space-x-8">
            <div className="w-full">
              <div className="border border-gray-300 rounded-md p-4 min-h-[250px]">
                <Editor
                  editorState={perspectivesEditorState}
                  onEditorStateChange={handlePerspectivesEditorChange}
                />
              </div>
            </div>
            <div className="w-full md:w-[450px] input-field">
              <UploadImage
                image={perspectivesImage}
                setImage={setPerspectivesImage}
              />
            </div>
          </div>
        </div>

        <div className="grid gap-4 w-full">
          {courseTestimonials.map((testimonial, index) => (
            <div key={index} className="border p-4 rounded-lg">
              <div className="w-full flex flex-col md:flex-row justify-start items-center gap-8">
                <div className="w-full flex flex-col md:flex-row justify-start items-start gap-4">
                  {/* Testimonial Content */}
                  <div className="w-full md:w-1/2 flex flex-col space-y-2">
                    <label className="font-medium text-gray-700">
                      {t("newCourse.testimonials.testimonial")} {index + 1}
                    </label>
                    <textarea
                      className="border rounded-lg p-2 text-gray-900 focus:outline-none h-[150px] resize-none"
                      value={testimonial.content}
                      onChange={(e) =>
                        updateTestimonialField(index, "content", e.target.value)
                      }
                      placeholder={t(
                        "newCourse.testimonials.contentPlaceholder"
                      )}
                      required
                    />
                  </div>

                  {/* Author Input */}
                  <div className="w-full md:w-1/2 flex flex-col space-y-2">
                    <label className="font-medium text-gray-700">
                      {t("newCourse.testimonials.authorPlaceholder")}{" "}
                      {index + 1}
                    </label>
                    <textarea
                      className="border rounded-lg p-2 text-gray-900 focus:outline-none h-[150px] resize-none"
                      value={testimonial.author}
                      onChange={(e) =>
                        updateTestimonialField(index, "author", e.target.value)
                      }
                      placeholder={t(
                        "newCourse.testimonials.authorPlaceholder"
                      )}
                      required
                    />
                  </div>
                </div>

                {/* Image Upload */}
                <div className="flex flex-col justify-start md:justify-center items-start md:items-center space-y-4 w-[400px]">
                  <img
                    alt="Author"
                    src={
                      testimonial.photo.preview ||
                      "assets/images/default-user.jfif"
                    }
                    className="w-[200px] h-[200px] object-cover border"
                  />
                  <input
                    type="file"
                    className="w-[230px]"
                    onChange={(e) => photoChangeHandlerTestimonial(index, e)}
                  />
                </div>
              </div>

              {/* Remove Button */}
              {index !== 0 && (
                <div className="mt-4">
                  <Button
                    text={t("newCourse.testimonials.removeTestimonial")}
                    type="primary"
                    customColor="#373737"
                    width="250px"
                    preventDefault={true}
                    onClick={() => removeTestimonial(index)}
                  />
                </div>
              )}
            </div>
          ))}
          {/* Add Testimonial Button */}
          <div>
            <Button
              text={t("newCourse.testimonials.addTestimonial")}
              type="primary"
              width="200px"
              preventDefault={true}
              onClick={() => addNewTestimonial()}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 my-6">
          {/* Row 1 */}
          <div className="col-span-4 md:col-span-1">
            <label className="block">{t("newCourse.pricePerPerson")}</label>
            <Input
              type="number"
              fullWidth
              value={price}
              required
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">{t("newCourse.currency")}</label>
            <RSelect
              options={currencyOptions}
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              label="Select Currency"
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">{t("newCourse.language")}</label>
            <RSelect
              options={languageOptions}
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              label="Select Language"
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <label className="block">{t("newCourse.createdBy")}</label>
            <Input
              type="text"
              fullWidth
              value={createdBy}
              onChange={(e) => setCreatedBy(e.target.value)}
            />
          </div>

          {/* Row 2 */}
          <div className="col-span-4 md:col-span-2">
            <label className="block">{t("newCourse.courseLevel")}</label>
            <RSelect
              options={courseLevels}
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              label="Select Course Level"
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <label className="block">{t("newCourse.attachment")}</label>
            <input
              className="fileInput"
              type="file"
              id="courseAttachment"
              onChange={(e) => {
                updateCourseAttachment(e);
              }}
            />
          </div>

          {/* Row 3 */}
          <div className="col-span-4 md:col-span-2">
            <MultiSelectInput
              text={t("newCourse.skillsRequired")}
              name="skills"
              onValueChange={setSkills}
              required
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <label className="block">{t("newCourse.domainLabel")}</label>
            <Select
              isMulti
              options={domains}
              value={selectedDomains}
              closeMenuOnSelect={false}
              onChange={(e: any) => setSelectedDomains(e)}
              placeholder={t("newCourse.domainLabel")}
              required
            />
          </div>

          {/* Row 4 */}
          <div className="col-span-4 md:col-span-2">
            <label className="block">{t("newCourse.categoryLabel")}</label>
            <RSelect
              label="Select category"
              fullWidth
              options={categories}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons">
          <Button
            text={t("newCourse.cancelButton")}
            type="primary"
            customColor="#373737"
            width="400px"
            preventDefault={true}
            onClick={() => navigate(-1)}
          />
          <Button
            text={t("newCourse.saveButton")}
            type="primary"
            width="400px"
            submit
          />
        </div>
      </form>
    </>
  );
}
