import React, { useState } from "react";
import Switch from "../../Switch";

interface InputProps {
  days: any[];
  setDays: (days: any[]) => void;
  label: string;
  required?: boolean;
}

const Availibility: React.FC<InputProps> = ({
  days,
  setDays,
  label,
  required,
}) => {
  const validateTime = (time: string): string => {
    let [hour, minute] = time.split(" ").join("").split(":");
    hour = parseInt(hour, 10).toString();
    minute = parseInt(minute, 10).toString();
    if (minute !== "00" && minute !== "30") {
      minute = parseInt(minute, 10) < 15 ? "00" : "30";
    }
    const result = `${hour.padStart(2, "0")}:${minute}`;
    console.log(time, result);
    return result;
  };

  const handleStartTimeChange = (index: number, time: string) => {
    const newDays = [...days];
    newDays[index].start = validateTime(time);
    setDays(newDays);
  };

  const handleEndTimeChange = (index: number, time: string) => {
    const newDays = [...days];
    newDays[index].end = validateTime(time);
    setDays(newDays);
  };

  const handleSwitchChange = (index: number, value: boolean) => {
    const newDays = [...days];
    newDays[index].enabled = value;
    setDays(newDays);
  };

  return (
    <div className="flex flex-col gap-0.5 w-full">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div className="flex flex-col items-center gap-[18px] sm:gap-2">
        {days.map((day, index) => (
          <div
            key={index}
            className={`flex justify-between items-center w-full transition-all duration-300 ${
              !day.enabled && "opacity-50"
            }`}
          >
            <div className="hidden sm:flex body-small-bold text-primary-gray-750 w-[85px]">
              {day.name}
            </div>
            <div className="flex flex-col gap-2 w-full sm:w-fit">
              <div className="sm:hidden flex items-center justify-between">
                <div className="body-small-bold text-primary-gray-750 w-[85px]">
                  {day.name}
                </div>
                <Switch
                  enabled={day.enabled}
                  setEnabled={(value) => handleSwitchChange(index, value)}
                />
              </div>
              <div className="caption-text flex items-center w-full sm:w-fit gap-3 text-primary-gray-500">
                <input
                  className={`body-small flex items-center w-full px-4 py-3 text-primary-gray-800 outline outline-1 outline-primary-gray-200 rounded-xl transition-all duration-300 ${
                    day.enabled &&
                    "hover:outline-primary-paradise-pink-500 focus:outline-2 focus:outline-primary-paradise-pink-500"
                  }`}
                  type="time"
                  value={day.start}
                  onChange={(e) => handleStartTimeChange(index, e.target.value)}
                />
                <span>to</span>
                <input
                  className={`body-small flex items-center w-full px-4 py-3 text-primary-gray-800 outline outline-1 outline-primary-gray-200 rounded-xl transition-all duration-300 ${
                    day.enabled &&
                    "hover:outline-primary-paradise-pink-500 focus:outline-2 focus:outline-primary-paradise-pink-500"
                  }`}
                  type="time"
                  value={day.end}
                  onChange={(e) => handleEndTimeChange(index, e.target.value)}
                />
              </div>
            </div>
            <div className="hidden sm:flex">
              <Switch
                enabled={day.enabled}
                setEnabled={(value) => handleSwitchChange(index, value)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Availibility;
