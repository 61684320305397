import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { ToastContainer } from "react-toastify";

import styles from "../auth.module.css";
import { Loading, Navbar } from "../../../components";
import Info from "./Steps/Info";
import HostDetails from "./Steps/HostDetails";
import { useBetaNotification } from "src/store/useBetaNotification";
import ClassroomDetails from "./Steps/ClassroomDetails";
import VerificationSent from "./Steps/VerificationSent";
import { useGoogleMaps } from "src/store/useGoogleMaps";
import env from "../../../config/env";
import ReviewInformation from "./Steps/ReviewInformation";

function HostUpgrade() {
  const navigate = useNavigate();
  const isMapsLoaded = useGoogleMaps(env.GOOGLE_MAPS_API_KEY!);

  const { isBetaVisible } = useBetaNotification();

  const [user, setUser] = useState<any>({});
  const [classroom, setClassroom] = useState<any>({});
  const [step, setStep] = useState<number>(1);

  const steps = [
    {
      index: 1,
      title: "Host Details",
    },
    {
      index: 2,
      title: "Classroom Details",
    },
    {
      index: 3,
      title: "Review Information",
    },
  ];

  console.log(classroom);

  if (!isMapsLoaded) {
    return <Loading />;
  }

  return (
    <main className={styles.main}>
      <ToastContainer theme="dark" />

      <Navbar />

      <div
        className={`flex flex-col gap-4 sm:gap-10 mx-5 w-[350px] h-fit sm:min-w-[592px] mb-14 ${
          isBetaVisible ? " mt-[168px]" : " mt-[120px]"
        }`}
      >
        {step < 5 && (
          <h3 className="text-body-large font-body-large sm:text-subheading sm:font-subheading text-primary-paradise-pink-800">
            Verify Host Account
          </h3>
        )}

        {step >= 2 && step < 5 && (
          <div className="flex items-center justify-between w-full">
            {steps.map((stepIndex, index) => (
              <div
                key={index}
                className="flex-1 flex flex-col items-center relative"
              >
                {index !== steps.length - 1 && (
                  <div
                    className={`absolute top-[25%] left-[50%] w-full h-px ${
                      index >= step - 2
                        ? "bg-primary-gray-300"
                        : "bg-primary-paradise-pink-900"
                    }`}
                  />
                )}

                <div
                  className={`z-10 flex items-center justify-center w-[38px] h-[38px] rounded-full text-body-medium-bold ${
                    index >= step - 1
                      ? "bg-primary-gray-50 text-primary-gray-400 outline outline-1 outline-primary-gray-300"
                      : "bg-primary-paradise-pink-900 text-white"
                  }`}
                >
                  {stepIndex.index}
                </div>

                <div
                  className={`mt-2 caption-text sm:w-fit ${
                    index >= step - 1
                      ? "text-primary-gray-400"
                      : "text-primary-paradise-pink-900"
                  } ${
                    index === 0
                      ? "text-start"
                      : index === steps.length - 1
                      ? "text-end"
                      : "text-center"
                  } ${index === 0 ? "w-[60px]" : "w-[75px]"}`}
                >
                  {stepIndex.title}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col items-center gap-5 w-full sm:gap-6">
          {step === 1 && <Info setStep={setStep} />}
          {step === 2 && (
            <HostDetails user={user} setStep={setStep} setUser={setUser} />
          )}
          {step === 3 && (
            <ClassroomDetails
              classroom={classroom}
              setStep={setStep}
              setClassroom={setClassroom}
            />
          )}
          {step === 4 && (
            <ReviewInformation
              user={user}
              classroom={classroom}
              setStep={setStep}
            />
          )}
          {step === 5 && <VerificationSent />}
        </div>
      </div>
    </main>
  );
}

export default observer(HostUpgrade);
