import React, { useState, useEffect, useRef } from "react";

type TailPosition =
  | "top"
  | "bottom"
  | "bottom-right"
  | "bottom-left"
  | "top-right"
  | "top-left";

interface BubbleMessageProps {
  text: string;
  tailPosition: TailPosition;
}

const BubbleMessage: React.FC<BubbleMessageProps> = ({
  text,
  tailPosition,
}) => {
  let style: any = {};

  switch (tailPosition) {
    case "bottom":
      style.left = "50%";
      style.transform = "translate(-50%, calc(-100% - 16px))";
      break;
    case "bottom-left":
      style.transform = "translate(0%, calc(-100% - 16px))";
      break;
    case "bottom-right":
      style.transform = "translate(0%, calc(-100% - 16px))";
      style.right = 0;
      break;
    default:
      break;
  }

  const tailStyles: { [key in TailPosition]: string } = {
    top: "after:content-[''] after:absolute after:top-[-10px] after:left-[50%] after:transform after:-translate-x-1/2 after:w-0 after:h-0 after:border-l-[10px] after:border-r-[10px] after:border-b-[10px] after:border-l-transparent after:border-r-transparent after:border-b-black",
    bottom:
      "after:content-[''] after:absolute after:bottom-[-10px] after:left-[50%] after:transform after:-translate-x-1/2 after:w-0 after:h-0 after:border-l-[10px] after:border-r-[10px] after:border-t-[10px] after:border-l-transparent after:border-r-transparent after:border-t-black",
    "bottom-left":
      "after:content-[''] after:absolute after:bottom-[-10px] after:left-0 after:transform after:w-0 after:h-0 after:border-l-0 after:border-r-[10px] after:border-t-[10px] after:border-l-transparent after:border-r-transparent after:border-t-black rounded-bl-none",
    "bottom-right":
      "after:content-[''] after:absolute after:bottom-[-10px] after:right-0 after:transform after:w-0 after:h-0 after:border-l-[10px] after:border-r-0 after:border-t-[10px] after:border-l-transparent after:border-r-transparent after:border-t-black rounded-br-none",
    "top-left":
      "after:content-[''] after:absolute after:top-[-10px] after:left-0 after:transform after:w-0 after:h-0 after:border-l-0 after:border-r-[10px] after:border-b-[10px] after:border-l-transparent after:border-r-transparent after:border-b-black rounded-tl-none",
    "top-right":
      "after:content-[''] after:absolute after:top-[-10px] after:right-0 after:transform after:w-0 after:h-0 after:border-l-[10px] after:border-r-0 after:border-b-[10px] after:border-l-transparent after:border-r-transparent after:border-b-black rounded-tr-none",
  };

  return (
    <div className="absolute" style={style}>
      <div className="relative flex items-center w-[246px]">
        <div
          className={`body-small relative bg-primary-paradise-pink-900 text-primary-gray-50 px-4 py-2.5 rounded-[10px] shadow-md ${tailStyles[tailPosition]}`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default BubbleMessage;
