import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../components";
import env from "../../../config/env";
import { useTranslation } from "react-i18next";

interface ClassroomProps {
    id: string;
    image: any;
    name: string;
    capacity: number;
    description: string;
    location: any;
    isPublic: boolean;
    handlePrivacy: () => void;
}

export default function Classroom({id, image, name, capacity, description, location, isPublic, handlePrivacy}: ClassroomProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();


    const handleDelete = () => {
        console.log("Delete Classroom");
    }

    return (
        <div className="classroom">
            <div className="image-container">
                <div className="icons">
                    <div className="icon" onClick={() => navigate("/editClassroom/" + id)}>
                        <img src="/images/icons/edit.svg" alt="edit" />
                    </div>
                    <div className="icon" onClick={handleDelete}>
                        <img src="/images/icons/delete-grey.svg" alt="delete" />
                    </div>
                </div>
                <img 
                    className="image" 
                    src={
                        image && Object.keys(image).length > 0
                            ? `${env.IMAGE_API}${image?.name}`
                            : "images/default-classroom.svg"
                    } 
                    alt="classroom-image" 
                />
            </div>
            <div className="header">
                <Link to={`/classroom/${id}`} target="blank"> 
                    <h2>{name}</h2>
                </Link>
                <span>{capacity}  {t("classroom.personsMax")}</span>
            </div>
            <p>{description}</p>
            <div className="location">
                <img src="/images/icons/location-gray.svg" alt="" />
                <p>{location?.name}</p>
            </div>
            {isPublic ?
                <Button 
                    icon="/images/icons/invisible-pink.svg"
                    type="secondary"
                    text={t("classroom.putOnDraft")}
                    onClick={handlePrivacy}
                />
                :
                <Button 
                    icon="/images/icons/visible.svg"
                    type="primary"
                    text={t("classroom.makePublic")}
                    onClick={handlePrivacy}
                />
            }
        </div>
    );
}