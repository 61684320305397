import { observer } from "mobx-react";

import styles from "./questions.module.css";
import Question from "./Question";
import { useTranslation } from "react-i18next";


function Questions() {
  const { t } = useTranslation();

  const questions = [
    {
      title: t("questions.faq.instructor.title"),
      content: [
        <p key="1">
          {t("questions.faq.instructor.content.part1")}{" "}
          <a
            href="https://community.classx.tech/teach-with-us/"
            className="text-primary-purple-400 underline cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            {t("questions.faq.instructor.content.linkText")}
          </a>
          .
        </p>,
      ],
    },
    {
      title: t("questions.faq.host.title"),
      content: [
        <p key="2">
          <b>{t("questions.faq.host.content.boldIntro")}</b>{" "}
          {t("questions.faq.host.content.part1")}{" "}
          <a
            href="https://community.classx.tech/list-your-space/"
            className="text-primary-purple-400 underline cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            {t("questions.faq.host.content.linkText")}
          </a>
          .
        </p>,
      ],
    },
    {
      title: t("questions.faq.enroll.title"),
      content: [
        <span key="3">
          {t("questions.faq.enroll.content.step1")}{" "}
          <a
            href="https://www.classx.tech"
            className="text-primary-purple-400 underline cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            classx.tech
          </a>
          .
        </span>,
        <span key="4">{t("questions.faq.enroll.content.step2")}</span>,
        <span key="5">{t("questions.faq.enroll.content.step3")}</span>,
        <span key="6">{t("questions.faq.enroll.content.step4")}</span>,
      ],
    },
    {
      title: t("questions.faq.cancelOrReschedule.title"),
      content: [
        <span key="7">{t("questions.faq.cancelOrReschedule.content.intro")}</span>,
        <ul key="8" className="list-disc">
          <li className="ml-5">{t("questions.faq.cancelOrReschedule.content.refundPolicy")}</li>
          <li className="ml-5">{t("questions.faq.cancelOrReschedule.content.reschedulePolicy")}</li>
        </ul>,
      ],
    },
    {
      title: t("questions.faq.onlineOrInPerson.title"),
      content: [
        <p key="9">{t("questions.faq.onlineOrInPerson.content")}</p>,
      ],
    },
    {
      title: t("questions.faq.contactSupport.title"),
      content: [
        <p key="10">{t("questions.faq.contactSupport.content.intro")}</p>,
        <ul key="11" className="list-disc">
          <li className="ml-5">
            {t("questions.faq.contactSupport.content.email")}{" "}
            <a
              href="mailto: hello@classx.tech"
              className="text-primary-purple-400 underline cursor-pointer"
            >
              hello@classx.tech
            </a>
          </li>
          <li className="ml-5">
            {t("questions.faq.contactSupport.content.phone")}{" "}
            <a
              href="tel: +1 (408) 569 7534"
              className="text-primary-purple-400 underline cursor-pointer"
            >
              +1 (408) 569 7534
            </a>{" "}
            (Mon-Fri, 9 AM - 5 PM EST)
          </li>
        </ul>,
      ],
    },
  ];

  return (
    <div className={styles.questions_content_content}>
      <div className="flex flex-col items-center gap-1.5 w-full text-center">
        <h4 className="text-body-large sm:text-heading lg:text-heading-medium font-body-large-bold sm:font-heading lg:font-heading-medium">
        <span className={styles.colored_span}>{t("questions.sectionTitleColored")}</span> {t("questions.sectionTitleNormal")}
        </h4>
        <h3 className="text-body-medium sm:text-body-large font-body-medium sm:font-body-large">
        {t("questions.subtitle")}
        </h3>
      </div>

      <div className={styles.questions_content_items}>
        {questions?.map((item: any, index: number) => (
          <Question key={index} question={item} />
        ))}
      </div>
    </div>
  );
}

export default observer(Questions);
