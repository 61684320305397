import React, { createContext, useContext, useState, ReactNode } from "react";

interface BetaNotificationContextType {
  isBetaVisible: boolean;
  hideBeta: () => void;
}

const BetaNotificationContext = createContext<
  BetaNotificationContextType | undefined
>(undefined);

interface BetaNotificationProviderProps {
  children: ReactNode;
}

export const BetaNotificationProvider: React.FC<
  BetaNotificationProviderProps
> = ({ children }) => {
  const [isBetaVisible, setIsBetaVisible] = useState<boolean>(() => {
    return localStorage.getItem("hideBeta") !== "true";
  });

  const hideBeta = () => {
    setIsBetaVisible(false);
    localStorage.setItem("hideBeta", "true");
  };

  return (
    <BetaNotificationContext.Provider value={{ isBetaVisible, hideBeta }}>
      {children}
    </BetaNotificationContext.Provider>
  );
};

export const useBetaNotification = (): BetaNotificationContextType => {
  const context = useContext(BetaNotificationContext);
  if (!context) {
    throw new Error(
      "useBetaNotification must be used within a BetaNotificationProvider"
    );
  }
  return context;
};
