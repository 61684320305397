import { useState } from "react";
import { observer } from "mobx-react";

import { Button, Input } from "../../../../components";
import ImageInput from "src/components/shared/Input/ImageInput";

interface IProps {
  user: any;
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function ProfileDetails({ user, setUser, setStep }: IProps) {
  const [image, setImage] = useState<File>(user?.image || null);
  const [bio, setBio] = useState<string>(user?.description || "");

  const handleNextStep = async () => {
    setUser((user: any) => ({ ...user, image, description: bio }));
    setStep(3);
  };

  const handleBack = () => {
    setStep(1);
  };

  return (
    <div className="flex flex-col gap-5 sm:gap-6 w-full">
      <h3 className="body-large">Profile Details</h3>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4 p-5 sm:px-8 sm:py-6 bg-primary-purple-50 border border-primary-purple-200 rounded-3xl sm:rounded-2xl">
          <img src="/images/icons/info-black.svg" />
          <p className="text-body-small sm:text-body-medium">
            These details will represent you on your instructor profile.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="w-full sm:w-[125px]">
            <ImageInput
              image={image}
              setImage={setImage}
              label="Profile Picture"
              required
            />
          </div>
          <div className="w-full sm:w-[447px]">
            <Input
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              type="textarea"
              required
              label="Bio"
              placeholder="Briefly introduce yourself to potential learners."
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          type="pale"
          text="Back"
          width="fit-content"
          onClick={handleBack}
        />
        <div className="w-full sm:w-[130px]">
          <Button
            type="primary_dark"
            text="Continue"
            onClick={handleNextStep}
            isDisabled={!image || !bio}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(ProfileDetails);
