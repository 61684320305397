import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useStore } from "../../../../store";
import styles from "./breadcrumbs.module.css";
import { useTranslation } from "react-i18next";


function PaymentBreadcrumbs() {
  const { t } = useTranslation();
  const { courseStore } = useStore();
  const course: any = courseStore.courseDetails;

  const links = [
    { path: "/", label: t("breadcrumbs.home") },
    ...(course?.sessionCourse && course?.sessionCourse?.title
      ? [
          { path: "/courses", label: t("breadcrumbs.courses") },
          {
            path: `/course/${course.sessionCourse.id}`,
            label: course.sessionCourse.title,
          },
        ]
      : []),
    { path: "/courses", label: t("breadcrumbs.cohorts") },
    { path: `/class/${course?.id}`, label: course?.title },
  ];

  return (
    <div className={styles.course_breadcrumbs}>
      <div className={styles.course_breadcrumbs_links}>
        {links.map((link, index) => (
          <div className={styles.link} key={index}>
            <Link to={link.path} className={styles.label}>
              {link.label}
            </Link>
            <div className={styles.arrow}>&gt;</div>
          </div>
        ))}
        <div className={styles.active}>{t("breadcrumbs.checkout")}</div>
        </div>
    </div>
  );
}
export default observer(PaymentBreadcrumbs);
