import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import { useStore } from "../../../store";
import { useNavigate, useLocation, redirect } from "react-router-dom";
import { Navbar } from "src/components/shared";
import Container from "src/components/shared/Container";
import { useTranslation } from "react-i18next";


function MailVerification() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const email = state?.email || "";
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);

  const { userStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate("/forgot-password", { replace: true }); 
    }
  }, [email, navigate]);

  const handleOtpChange = (value: string, index: number) => {
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (newOtp.join("").length === 4) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`)?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("Text").trim();

    const numericData = pasteData.replace(/\D/g, "").slice(0, 4);

    const newOtp = numericData.split("");
    while (newOtp.length < 4) newOtp.push("");

    setOtp(newOtp);
    setIsButtonDisabled(newOtp.join("").length !== 4);
    e.preventDefault();
  };

  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        document.getElementById(`otp-${index - 1}`)?.focus();
      }
    }
  };

 const verifyCode = async () => {
  const code = otp.join("");

  if (isNaN(Number(code)) || code.length !== 4) {
    toast.error(t("mailVerification.invalidCode"));
    return;
  }
  setIsSubmitDisabled(true);

  try {
    const res = await userStore.verifyForgotPasswordCode(email, Number(code));
    if (res) {
       toast.success(res.message);
      navigate("/reset-password", { state: { email, code: Number(code) } });
    } else {
      toast.error(res || "Invalid code or email");
      setIsSubmitDisabled(false);
    }
  } catch (error: any) {
    toast.error(error.response?.data?.message || "Error verifying code");
    setIsSubmitDisabled(false);
  }
};



  const resendForgotPasswordCode = async () => {
    setResendDisabled(true);
    try {
      const res = await userStore.resendForgotPasswordCode(email);
      if (res?.message) {
        // toast.success(res);
        //   navigate("/mail-verification");
      } else {
        toast.error("An error occurred while sending the request");
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || "Error resending OTP code");
    }
    setTimeout(() => setResendDisabled(false), 30000);
  };

  const RedirectToResetPassword = () => {
    let path = `/forgot-password`;
    navigate(path);
  };

  return (
    <>
      <Navbar />
      <ToastContainer theme="dark" />
      <Container>
      <div className="flex items-center justify-center py-5 px-4 sm:px-6">
      <div className="flex flex-col items-center w-full space-y-1 max-h-[576px] max-w-[482px]">
            {/* Stepper */}
            <ol className="flex justify-between items-center w-full mb-6 px-2">
            <li className="flex-1 h-[5px] mx-1 bg-[#E83C70] rounded-full"></li>
              <li className="flex-1 h-[5px] mx-1 bg-[#E83C70] rounded-full"></li>
              <li className="flex-1 h-[5px] mx-1 bg-pink-100 rounded-full"></li>
            </ol>

            <div className="bg-white rounded-xl border border-gray-200 w-full max-w-[482px] px-6 py-8">
            <div className="flex flex-col items-center mb-4">
                <svg
                  width="120"
                  height="120"
                  viewBox="0 0 120 120"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    opacity="0.5"
                    cx="60"
                    cy="60"
                    r="60"
                    fill="url(#paint0_linear_362_4666)"
                  />
                  <g opacity="0.6" filter="url(#filter0_f_362_4666)">
                    <path
                      d="M79.3095 64.6863L62.3476 59.0955C61.8002 58.9164 61.1986 58.8232 60.5889 58.8232C59.9792 58.8232 59.3776 58.9164 58.8303 59.0955L41.8684 64.6863C40.955 64.9819 40.185 65.4433 39.6466 66.0174C39.1082 66.5916 38.8232 67.2553 38.8242 67.9325V79.8992C38.8242 81.9008 41.2941 83.5291 44.3305 83.5291H76.8474C79.8837 83.5291 82.3536 81.9008 82.3536 79.8992V67.9325C82.3547 67.2553 82.0696 66.5916 81.5312 66.0174C80.9928 65.4433 80.2228 64.9819 79.3095 64.6863ZM60.2372 60.9507C60.3467 60.9149 60.467 60.8962 60.5889 60.8962C60.7109 60.8962 60.8312 60.9149 60.9407 60.9507L77.3983 66.3736L60.6861 71.8834C60.5766 71.9192 60.4563 71.9378 60.3344 71.9378C60.2124 71.9378 60.0921 71.9192 59.9826 71.8834L43.5221 66.4598L60.2372 60.9507Z"
                      fill="#FF6D99"
                    />
                  </g>
                  <path
                    d="M80.2386 45.9633L61.9014 36.9115C61.3096 36.6215 60.6592 36.4707 60.0001 36.4707C59.341 36.4707 58.6906 36.6215 58.0989 36.9115L39.7617 45.9633C38.7743 46.4419 37.9418 47.1888 37.3598 48.1184C36.7777 49.048 36.4696 50.1226 36.4707 51.2191V70.5936C36.4707 73.8344 39.1409 76.4707 42.4234 76.4707H77.5768C80.8594 76.4707 83.5295 73.8344 83.5295 70.5936V51.2191C83.5306 50.1226 83.2225 49.048 82.6405 48.1184C82.0584 47.1888 81.226 46.4419 80.2386 45.9633ZM59.6199 39.9151C59.7382 39.8571 59.8683 39.827 60.0001 39.827C60.1319 39.827 60.262 39.8571 60.3804 39.9151L78.1724 48.6951L60.1052 57.6157C59.9868 57.6737 59.8567 57.7039 59.7249 57.7039C59.5931 57.7039 59.463 57.6737 59.3447 57.6157L41.5495 48.8347L59.6199 39.9151Z"
                    fill="url(#paint1_linear_362_4666)"
                  />
                  <defs>
                    <filter
                      id="filter0_f_362_4666"
                      x="20.2242"
                      y="40.2232"
                      width="80.7293"
                      height="61.9061"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="9.3"
                        result="effect1_foregroundBlur_362_4666"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_362_4666"
                      x1="60"
                      y1="0"
                      x2="60"
                      y2="120"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFE8EF" stop-opacity="0" />
                      <stop offset="1" stop-color="#FFE8EF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_362_4666"
                      x1="60.0001"
                      y1="36.4707"
                      x2="60.0001"
                      y2="76.4707"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#F196B1" />
                      <stop offset="0.785" stop-color="#F44E81" />
                    </linearGradient>
                  </defs>
                </svg>

                <h4 className="text-xl md:text-2xl font-semibold text-[#E83C70] mt-4">
                {t("mailVerification.title")}
                </h4>
              </div>
              <p className="text-center text-primary-paradise-pink-850 mb-6 leading-[25px] text-base font-normal sm:text-sm sm:leading-6">
              {t("mailVerification.description")} <br />
                <span className="font-bold text-primary-paradise-pink-850 leading-[25px] text-base sm:text-sm">
                  {email}
                </span>
              </p>

              <form id="otp-form" className="space-y-7 ">
              <div className="flex items-center justify-center gap-2">
              {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-${index}`}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onKeyDown={(e) => handleBackspace(e, index)}
                      onChange={(e) => handleOtpChange(e.target.value, index)}
                      onPaste={handlePaste}
                      className="w-[54px] h-[54px] text-center text-xl sm:text-2xl font-extrabold text-gray-900 bg-[#FFFAFB]  border-gray-300 rounded-xl border-2 outline-none focus:border-[#E83C70]  focus:ring-pink-100"
                    />
                  ))}
                </div>

                <div className="flex flex-col items-center space-y-3 mt-5">
                <button
                    onClick={() => RedirectToResetPassword()}
                    type="button"
                    className="w-[240px] sm:w-[240px] lg:w-[322px] text-sm leading-[26px] font-semibold text-[#CA325C] bg-pink-100 hover:bg-pink-200 rounded-lg py-3 transition duration-200"
                    >
                    {t("mailVerification.changeEmailAddress")}
                    </button>
                  <button
                    type="button"
                    onClick={verifyCode}
                    disabled={isButtonDisabled}
                    className={`bg-[#E83C70] border-2 border-transparent hover:border-[#E83C70] hover:text-[#E83C70] hover:bg-white focus:ring-4 focus:outline-none focus:ring-[#FFFFFF]/50 w-[240px] sm:w-[240px] lg:w-[322px] text-sm leading-[26px] font-semibold text-white rounded-lg py-3 transition duration-200 ${
                      isButtonDisabled
                        ? "bg-[#E83C70] opacity-50 cursor-not-allowed"
                        : "bg-[#E83C70] hover:bg-pink-600"
                    }`}
                  >
                    {t("mailVerification.continueButton")}
                    </button>
                </div>
              </form>
              <div className="sm:text-xs text-center text-sm text-[#000000] leading-[24px] font-normal font-inter mt-4 mb-4">
              {t("mailVerification.notReceived")}{" "}
              <a
                  className={`text-center text-sm leading-[24px] font-normal underline ${
                    resendDisabled
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-[#E83C70] hover:text-pink-600"
                  }`}
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!resendDisabled) {
                      resendForgotPasswordCode();
                    }
                  }}
                >
                  {t("mailVerification.resendCode")}
                  </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default observer(MailVerification);
