import { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import styles from "./navbar.module.css";
import { Button, Popup } from "../../..";
import { useStore } from "../../../../store";
import { useTranslation } from "react-i18next";

interface IProps {
  type: "course" | "class";
}

function CourseNavBar({ type }: IProps) {
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();
  const { t } = useTranslation();

  const token = localStorage.getItem("token");

  const [active, setActive] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let course: any = courseStore.courseDetails;
  // if (type === "cohort") {
  //   course = course?.sessionCourse;
  // }
  const stories: any = course?.testimonials || [];
  const goals: any =
    course?.objectifs?.image?.name ||
    course?.sessionCourse?.objectifs?.image?.name ||
    undefined;
  const perspectives: any =
    course?.perspectives?.image?.name ||
    course?.sessionCourse?.perspectives?.image?.name ||
    undefined;

  const onClick = (index: number, link: string) => {
    setActive(index);
    const element = document.getElementById(link);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const participate = async () => {
    courseStore.selectCourse(course.id);

    if (userStore.user) {
      navigate(`/payment/${course.id}`);
    } else {
      setIsOpen(true);
    }
  };

  const lookForSessions = async () => {
    courseStore.selectCourse(course.id);

    if (userStore.user) {
      //await courseStore.lookForSessions(course.id);
      navigate("/search");
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.course_navbar}>
      <div className={styles.course_navbar_content}>
        <div className={styles.course_navbar_links}>
          <div
            onClick={() => onClick(1, "about-course")}
            className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 1
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
          >
            <span>
              {t("buttons.about")} {t("buttons.course")}{" "}
            </span>
          </div>

          <div
            onClick={() => onClick(2, "course-content")}
            className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 2
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
          >
            <span> {t("buttons.courseContent")} </span>
          </div>

          {/* {course?.tutors &&
          course?.tutors?.fullName &&
          course?.tutors.fullName !== "wecodeland" &&
          token ? (
            <div
              onClick={() => onClick(3, "about-instructor")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 3
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span>
                {t("buttons.about")}{" "}
                {type === "class" ? "instructor" : "creator"}
              </span>
            </div>
          ) : null} */}

          {type === "class" && (
            <div
              onClick={() => onClick(4, "schedule")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 4
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span> {t("buttons.timetable")} </span>
            </div>
          )}

          {(goals || perspectives) && (
            <div
              onClick={() => onClick(5, "goals-prospects")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 5
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span>{t("buttons.goalsAndPerspectives")}</span>
            </div>
          )}

          {type === "course" && (
            <div
              onClick={() => onClick(8, "upcoming-cohorts")}
              className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 8
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
            >
              <span>{t("buttons.upcomingCohorts")}</span>
            </div>
          )}

          {stories.length > 0 && stories[0].author?.length > 0 && (
            <div
              onClick={() => onClick(6, "success-stories")}
              className={`
                                ${styles.course_navbar_item}
                                ${
                                  active === 6
                                    ? styles.course_navbar_item_active
                                    : ""
                                }
                            `}
            >
              <span> {t("buttons.successStories")} </span>
            </div>
          )}

          <div
            onClick={() => onClick(7, "faqs")}
            className={`
                            ${styles.course_navbar_item}
                            ${
                              active === 7
                                ? styles.course_navbar_item_active
                                : ""
                            }
                        `}
          >
            <span> {t("buttons.faqs")} </span>
          </div>
        </div>

        {/* {type === "course" && (
          <Button
            type="primary"
            text={t("buttons.participate")}
            width="170px"
            onClick={() => participate()}
          />
        )} */}

        {type === "class" && (
          <div className="w-auto">
            <Button
              type="primary"
              text={t("buttons.viewUpcomingCohorts")}
              onClick={() => lookForSessions()}
            />
          </div>
        )}
      </div>

      {isOpen && (
        <Popup
          type={type}
          isOpen={isOpen}
          course={course.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default observer(CourseNavBar);
