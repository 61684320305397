import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import env from "../../../../config/env";
import { useTranslation } from "react-i18next";

import styles from "../../auth.module.css";
import { Input, Button } from "../../../../components";
import { useStore } from "../../../../store";
import { validateEmail } from "../../../../utils/validation";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function EmailInput({ setUser, setStep }: IProps) {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const { t } = useTranslation();

  const LinkedInApi = {
    clientId: env.LINKEDIN_CLIENT_ID,
    redirectUrl: env.LINKEDIN_REDIRECT_URL,
    oauthUrl: env.LINKEDIN_OAUTH_URL,
    scope: env.LINKEDIN_SCOPE,
    state: env.LINKEDIN_STATE,
  };

  const isLinkedInAuthRef = useRef<boolean>(false);

  const [email, setEmail] = React.useState<string>("");
  const [agree, setAgree] = React.useState<boolean>(false);
  const [isButtonClicked, setIsButtonClicked] = React.useState<boolean>(false);

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: "code", code: code }, "*");
      window.close();
    }
    window.addEventListener("message", handlePostMessage);
    return () => window.removeEventListener("message", handlePostMessage);
  }, []);

  const handlePostMessage = (event: any) => {
    if (event.data.type === "code" && !isLinkedInAuthRef.current) {
      isLinkedInAuthRef.current = true;
      const { code } = event.data;
      handleLinkedInSuccess(code);
    }
  };

  const getCodeFromWindowURL = (url: any) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const handleNextStep = async () => {
    HubSpotsignupStore.setEmail(email);
    // await HubSpotsignupStore.submitToHubspot();

    // mail sending logic
    setIsButtonClicked(true);
    const result = await userStore.sendVerificationCode(email);
    if (result) {
      setUser((user: any) => ({ ...user, email }));
      setStep(2);
    } else {
      toast.error("Email already exist!");
    }
    setIsButtonClicked(false);
  };

  const handleOnChangeAgree = () => {
    setAgree(!agree);
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleSuccess(tokenResponse),
  });

  const handleGoogleSuccess = async (response: any) => {
    try {
      const googleToken = response.access_token;
      const res = await userStore.signUpWithGoogle(googleToken);
      if (res) {
        console.log("google", res);
        setStep(4);
        setUser((user: any) => ({
          ...user,
          email: userStore?.user?.email,
          id: userStore?.user?.id,
        }));
      } else {
        toast(t("auth.googleSignUpFailed"));
      }
    } catch (error: any) {
      if (error.response.data.error.code === "USER_EXISTS") {
        handleGoogleLogin(response);
      } else {
        toast(error.response.data.error.message);
      }
    }
  };

  const handleGoogleLogin = async (response: any) => {
    try {
      const token = response.access_token;
      const res = await userStore.loginWithGoogle(token);
      if (res) {
        // localStorage.removeItem("registrationUser");
        // localStorage.removeItem("registrationStep");
        navigate("/");
      } else {
        toast(t("auth.googleLoginFailed"));
      }
    } catch (error: any) {
      console.log("error", error);

      toast(error.response.data.error.message);
    }
  };

  const handleLinkedInSuccess = async (code: any) => {
    try {
      const linkedinToken = code;
      const res = await userStore.signUpWithLinkedIn(linkedinToken);
      if (res) {
        setStep(4);
        setUser((user: any) => ({
          ...user,
          email: userStore?.user?.email,
          id: userStore?.user?.id,
        }));
      } else {
        toast(t("auth.linkedInFailed"));
      }
    } catch (error: any) {
      toast(error.response.data.error.message);
    }
  };

  const showPopup = () => {
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrlComplete = `${oauthUrl}&client_id=${encodeURIComponent(
      clientId!
    )}&scope=${encodeURIComponent(
      scope!
    )}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlComplete,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  return (
    <>
      <img src="/images/logo-pink.svg" alt="ClassX" width={80} />

      <div className="flex flex-col items-center gap-2 text-center sm:w-[377px]">
        <span className="text-body-large font-body-large sm:text-subheading sm:font-subheading">
          {t("auth.headingMain")}
        </span>
        <span className="body-medium">{t("auth.headingSub")}</span>
      </div>

      <div className="flex flex-col gap-6 sm:gap-8 max-w-[300px] sm:max-w-[334px]">
        <div className={styles.form}>
          <Input
            label={t("auth.emailLabel")}
            required
            value={email}
            placeholder="example@mail.com"
            validation={validateEmail}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className={styles.checkbox_container}>
            <input
              type="checkbox"
              id="agree"
              name="agree"
              value="Bike"
              checked={agree}
              onChange={handleOnChangeAgree}
              className="cursor-pointer"
            />
            <label
              htmlFor="agree"
              className="text-caption-text font-caption-text sm:text-body-small sm:font-body-small cursor-pointer"
            >
              {" "}
              {t("auth.agreeTextPart1")}{" "}
              <a
                rel="noreferrer"
                href="https://blog.classx.tech/terms"
                target="_blank"
                className={styles.privacy_link}
              >
                {t("auth.termsAndConditions")}
              </a>{" "}
              {t("auth.agreeTextPart2")}{" "}
              <a
                rel="noreferrer"
                href="https://blog.classx.tech/privacy-policy/"
                target="_blank"
                className={styles.privacy_link}
              >
                {t("auth.privacyPolicy")}
              </a>
            </label>
          </div>
        </div>
        <Button
          type="primary"
          text={t("auth.continue")}
          onClick={handleNextStep}
          isDisabled={!validateEmail(email) || !agree || isButtonClicked}
        />
        <div className="h-px w-full bg-black bg-opacity-5"></div>
        <div className={styles.social_buttons}>
          <Button
            type="tertiary"
            text={t("auth.google")}
            onClick={() => googleSignUp()}
            icon="/images/icons/google-colored.svg"
          />
          <Button
            type="tertiary"
            text={t("auth.linkedin")}
            onClick={showPopup}
            icon="/images/icons/linkedin-colored.svg"
          />
        </div>
      </div>

      <div className="text-caption-text font-caption-text sm:text-body-small sm:font-body-small">
        <span>{t("auth.alreadyHaveAccount")} </span>
        <Link to="/login">
          <span className={styles.privacy_link}>{t("auth.signIn")}</span>
        </Link>
      </div>
    </>
  );
}

export default observer(EmailInput);
