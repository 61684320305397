import React, { useEffect } from "react";

interface IProps {
  course: any;
  id: number;
  expanded: boolean;
  isOldSyllabus?: boolean;
}

export default function ContentItem({
  course,
  id,
  expanded,
  isOldSyllabus,
}: IProps) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <>
      {isOldSyllabus ? (
        <div
          key={id}
          className={`border-b-2 border-primary-gray-100 cursor-pointer transition-colors duration-200 active:bg-primary-purple-100 ${
            isExpanded
              ? "bg-primary-purple-50 hover:bg-[#FAF9FC]"
              : "bg-primary-gray-50 hover:bg-primary-purple-50"
          }`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex justify-between items-center w-full p-3 sm:px-8">
            <div>
              <div className="caption-text">Section {id + 1}</div>
              <div className="body-medium">{course?.text}</div>
            </div>
            <img
              src={
                isExpanded
                  ? "/images/icons/arrow-up-purple1.svg"
                  : "/images/icons/arrow-down-pink.svg"
              }
            />
          </div>

          {/* {isExpanded && (
            <div className="body-small pl-4 mx-3 sm:mx-8 mb-[30px] border-l-2 border-primary-paradise-pink-500">
              {course?.lessons?.map((lesson: any) => (
                <div key={lesson?.id} className={styles.chapter_body_item}>
                  <img
                    width={24}
                    height={24}
                    src="/images/icons/article.svg"
                    alt="Article icon"
                  />
                  <span className={styles.chapter_body_item_title}>
                    {lesson?.title}
                  </span>
                </div>
              ))}
            </div>
          )} */}
        </div>
      ) : (
        <div
          key={id}
          className={`border-b-2 border-primary-gray-100 cursor-pointer transition-colors duration-200 active:bg-primary-purple-100 ${
            isExpanded
              ? "bg-primary-purple-50 hover:bg-[#FAF9FC]"
              : "bg-primary-gray-50 hover:bg-primary-purple-50"
          }`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex justify-between items-center w-full p-3 sm:px-8">
            <div>
              <div className="caption-text">Section {id + 1}</div>
              <div className="body-medium">{course?.title}</div>
            </div>
            <img
              src={
                isExpanded
                  ? "/images/icons/arrow-up-purple1.svg"
                  : "/images/icons/arrow-down-pink.svg"
              }
            />
          </div>

          <div
            className={`overflow-hidden transition-[max-height] duration-[0.1s] ease-[cubic-bezier(0.34, 0.32, 0.43, 0.9)] ${
              isExpanded ? "max-h-screen" : "max-h-0"
            }`}
          >
            <div className="body-small pl-4 mx-3 sm:mx-8 mb-[30px] border-l-2 border-primary-paradise-pink-500">
              <div dangerouslySetInnerHTML={{ __html: course?.content }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
