import { useStore } from "../../../../store";
import { observer } from "mobx-react";
import env from "../../../../config/env";

function AboutCreator() {
  const { courseStore } = useStore();

  const creator: any = courseStore.courseDetails;
  const creatorMD: any = courseStore.courseDetails.creatorMobileData;

  const image = creatorMD?.attachmentPhoto?.name;

  return (
    <div className="flex flex-col gap-4 w-full" id="about-creator">
      <h3 className="body-large-bold">About the Creator</h3>
      <div className="flex flex-col gap-[22px] sm:gap-[30px] p-4 sm:p-[50px] border border-primary-gray-200 rounded-[36px] sm:rounded[54px] bg-primary-gray-50">
        <div className="flex items-center gap-4 sm:gap-[26px] lg:gap-[30px]">
          <img
            alt=""
            className="hidden lg:block size-[186px] rounded-full"
            src={
              image ? `${env.IMAGE_API}${image}` : "/images/default-user.svg"
            }
          />
          <div className="flex flex-col gap-[22px]">
            <div className="flex items-center gap-4 sm:gap[26px] lg:gap-0">
              <img
                alt=""
                className="block lg:hidden size-[76px] sm:size-[116px] rounded-full"
                src={
                  image
                    ? `${env.IMAGE_API}${image}`
                    : "/images/default-user.svg"
                }
              />
              <div className="flex flex-col sm:gap-1.5">
                <h3 className="text-body-large font-body-large sm:text-heading sm:font-heading-medium text-primary-purple-850">
                  {creator?.courseCreator}
                </h3>
                {creatorMD?.creatorTitle && (
                  <span className="text-caption-text font-caption-text-bold sm:text-body-small sm:font-body-small-bold text-primary-purple-800">
                    {creatorMD?.creatorTitle}
                  </span>
                )}
              </div>
            </div>
            <p className="body-small pl-4 border-l-[3px] h-fit border-primary-purple-500 text-primary-paradise-pink-800">
              {creatorMD?.creatorDescription}
            </p>
          </div>
        </div>

        <div className="flex flex-col sm:block gap-2 sm:gap-4">
          <div className="flex gap-2 sm:gap-4">
            <div className="flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
              <img width={20} height={20} src="/images/icons/star-purple.svg" />
              <span className="heading-4 flex flex-col items-center gap-0.5">
                {creatorMD?.ReviewRating?.toLocaleString() || 0}
                <span className="caption-text text-primary-purple-850">
                  Reviews
                </span>
              </span>
            </div>

            <div className="hidden sm:flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
              <img width={20} height={20} src="/images/icons/user-purple.svg" />
              <span className="heading-4 flex flex-col items-center gap-0.5">
                {creatorMD?.NbrofStudents?.toLocaleString() || 0}
                <span className="caption-text text-primary-purple-850">
                  Students
                </span>
              </span>
            </div>

            <div className="flex flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
              <img
                alt=""
                width={20}
                height={20}
                src="/images/icons/course-purple.svg"
              />
              <span className="heading-4 flex flex-col items-center gap-0.5">
                {creatorMD?.nbrofCourses?.toLocaleString() || 0}
                <span className="caption-text text-primary-purple-850">
                  Courses
                </span>
              </span>
            </div>
          </div>

          {/* <div className="flex sm:hidden flex-col items-center gap-2 py-3.5 w-full border border-primary-purple-100 rounded-2xl bg-primary-purple-50">
            <img width={20} height={20} src="/images/icons/user-purple.svg" />
            <span className="heading-4 flex flex-col items-center gap-0.5">
              {creator?.studentsCount?.toLocaleString() || 0}
              <span className="caption-text text-primary-purple-850">
                Students
              </span>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default observer(AboutCreator);
