import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./feedbacks.module.css";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => {
  return (
    <button
      className={`custom-dot h-[3px] rounded-full mx-1.5 transition-all duration-300 ${
        active
          ? "active bg-primary-paradise-pink-800 w-6"
          : "bg-primary-gray-300 w-5"
      }`}
      onClick={onClick}
    />
  );
};

const Feedbacks: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const feedbacks = [
    {
      name: t("feedbacks.testimonials.julia.name"),
      profession: t("feedbacks.testimonials.julia.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.julia.feedback"),
      rating: 5,
      image: "/images/feedbacks/julia.webp",
    },
    {
      name: t("feedbacks.testimonials.katherine.name"),
      profession: t("feedbacks.testimonials.katherine.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.katherine.feedback"),
      rating: 5,
      image: "/images/feedbacks/katherine.webp",
    },
    {
      name: t("feedbacks.testimonials.justine.name"),
      profession: t("feedbacks.testimonials.justine.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.justine.feedback"),
      rating: 5,
      image: "/images/feedbacks/justine.webp",
    },
    {
      name: t("feedbacks.testimonials.renan.name"),
      profession: t("feedbacks.testimonials.renan.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.renan.feedback"),
      rating: 5,
      image: "/images/feedbacks/renan.webp",
    },
    {
      name: t("feedbacks.testimonials.mikhael.name"),
      profession: t("feedbacks.testimonials.mikhael.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.mikhael.feedback"),
      rating: 5,
      image: "/images/feedbacks/misha.webp",
    },
    {
      name: t("feedbacks.testimonials.jing.name"),
      profession: t("feedbacks.testimonials.jing.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.jing.feedback"),
      rating: 5,
      image: "/images/feedbacks/jin.webp",
    },
    {
      name: t("feedbacks.testimonials.deepak.name"),
      profession: t("feedbacks.testimonials.deepak.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.deepak.feedback"),
      rating: 5,
      image: "/images/feedbacks/deepak.webp",
    },
    {
      name: t("feedbacks.testimonials.adithi.name"),
      profession: t("feedbacks.testimonials.adithi.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.adithi.feedback"),
      rating: 5,
      image: "/images/feedbacks/adithi.webp",
    },
    {
      name: t("feedbacks.testimonials.dhouha.name"),
      profession: t("feedbacks.testimonials.dhouha.profession"),
      verified: true,
      feedback: t("feedbacks.testimonials.dhouha.feedback"),
      rating: 5,
      image: "/images/feedbacks/dhouha.webp",
    },
  ];

  const responsive = {
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <div className="mt-10">
      <div className={styles.gradient}></div>
      <div className={styles.feedbacks}>
        <div className="flex justify-center items-center gap-4 p-2.5">
          <img src="/images/icons/star2.svg" />
          <h3 className="text-body-large font-body-large-bold sm:text-subheading sm:font-subheading text-center">
            {t("feedbacks.title")}
          </h3>
          <img src="/images/icons/star1.svg" />
        </div>

        <div className="block sm:hidden">
          <Carousel
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            arrows={false}
            showDots
            itemClass="px-2"
            partialVisbile
            containerClass="px-4"
            customDot={<CustomDot />}
          >
            {feedbacks.map((feedback, index) => (
              <FeedbackItem key={index} feedback={feedback} />
            ))}
          </Carousel>
        </div>
        <div className="hidden sm:block">
          <div className="flex justify-center space-x-4 mb-4">
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={0} feedback={feedbacks[0]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={1} feedback={feedbacks[1]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={2} feedback={feedbacks[2]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={3} feedback={feedbacks[3]} />
            </div>
          </div>

          <div className="flex justify-center space-x-4 mb-4">
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={4} feedback={feedbacks[4]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={5} feedback={feedbacks[5]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={6} feedback={feedbacks[6]} />
            </div>
          </div>

          <div className="flex justify-center space-x-4">
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={7} feedback={feedbacks[7]} />
            </div>
            <div className="min-w-[289px] max-w-[289px]">
              <FeedbackItem key={8} feedback={feedbacks[8]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedbackItem: React.FC<{ feedback: any }> = ({ feedback }) => {
  return (
    <div className="flex flex-col items-center gap-4 px-5 py-4 border border-black border-opacity-5 bg-primary-gray-50 rounded-2xl h-fit mb-5 sm:m-0">
      <div className="flex flex-col items-center gap-2">
        <img
          className="rounded-full fit-cover"
          src={feedback.image}
          alt={feedback.name}
          width={84}
          height={84}
        />
        <div className="flex flex-col items-center gap-0.5">
          <div className="body-large">{feedback.name}</div>
          <div className="caption-text text-primary-paradise-pink-600">
            {feedback.profession}
          </div>
          {feedback.verified && (
            <div className="flex gap-0.5 items-center caption-text-bold text-tertiary-success-700">
              <img src="/images/icons/success-green.svg" />
              <span>Verified Student</span>
            </div>
          )}
        </div>
      </div>

      <p className="body-small text-center">{feedback.feedback}</p>

      <div className="flex gap-0.5">
        {new Array(Math.ceil(feedback.rating)).fill(null).map((_, i) => (
          <img key={i} src="/images/icons/star.svg" />
        ))}
      </div>
    </div>
  );
};

export default observer(Feedbacks);
