import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import env from "../../../../config/env";
import { useTranslation } from "react-i18next";

import "./header.css";
import { useStore } from "../../../../store";
import Slider from "react-slider";
import { Button, LocationInput } from "src/components/shared";
import SearchModal from "./MobileSearchPopup";
import ListItem from "./MobileSearchPopup/ListItem";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { set, setTime } from "react-datepicker/dist/date_utils";

type Option = {
  value: string;
  label: string;
};

const MIN_PRICE = 0;
const MAX_PRICE = 1000;
export const SearchBar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { courseStore, userStore } = useStore();
  const params = useParams();

  const dropdownRef = useRef<any>(null);

  const categories: any[] = courseStore?.domains;
  const filter = courseStore.filter;

  const [search, setSearch] = useState<string>(filter?.search || "");
  const [location, setLocation] = useState<Option | null>(
    filter?.location || null
  );
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [category, setCategory] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isMobilePopupOpen, setIsMobilePopupOpen] = useState<boolean>(false);
  const [difficulty, setDifficulty] = useState<string>(filter.difficulty);
  const [selectedDifficulties, setSelectedDifficulties] = useState<string[]>(
    []
  );
  const [priceRange, setPriceRange] = useState([
    filter.priceMin,
    filter.priceMax,
  ]);
  const [placeId, setPlaceId] = useState<string | null>("");
  const [focus, setFocus] = useState<boolean>(false);
  const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>(false);

  const [minError, setMinError] = useState<boolean>(false);
  const [maxError, setMaxError] = useState<boolean>(false);
  const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState(false);

  useEffect(() => {
    let searchStorage = sessionStorage.getItem("search");
    if (searchStorage) {
      let searchStorageObject = JSON.parse(searchStorage);

      if (searchStorageObject.keyword) {
        setSearch(searchStorageObject.keyword);
      }
      if (searchStorageObject.domains) {
        setSelectedDomains(searchStorageObject.domains);
      }
      if (searchStorageObject.location) {
        setLocation(searchStorageObject.location.locationName);
        setPlaceId(searchStorageObject.location.placeId);
      }
      if (searchStorageObject.selectedDifficulties) {
        setSelectedDifficulties(searchStorageObject.selectedDifficulties);
      }
      if (searchStorageObject.difficulty) {
        setDifficulty(searchStorageObject.difficulty);
      }
      if (searchStorageObject.priceMin && searchStorageObject.priceMax) {
        setPriceRange([
          searchStorageObject.priceMin,
          searchStorageObject.priceMax,
        ]);
      }
    }
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropdown]);

  // const difficulties = [
  //   t("searchBar.beginner"),
  //   t("searchBar.intermediate"),
  //   t("searchBar.advanced"),
  // ];

  const difficulties = ["Beginner", "Intermediate", "Advanced"];

  const icons = [
    "/images/icons/topics/coding.svg",
    "/images/icons/topics/design.svg",
    "/images/icons/topics/digital-marketing.svg",
    "/images/icons/topics/product.svg",
    "/images/icons/topics/life-skills.svg",
    "/images/icons/topics/data-science.svg",
    "/images/icons/topics/ai.svg",
  ];

  const domainsIcons = categories.map((category: any, index: number) => {
    return { domain: category, icon: category.icon };
  });

  const sendSearchEvent = async (search: any) => {
    const payload = {
      event_name: "search",
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      action_source: "website",
      user_data: {
        client_user_agent: navigator.userAgent,
        em: [userStore.user?.email || ""],
        ph: [userStore.user?.phone || ""],
        country: userStore.user?.country || "",
        ge: userStore.user?.gender || "",
        fn: userStore.user?.fullName || "",
        ln: userStore.user?.fullName || "",
      },
      custom_data: {
        search: search,
      },
    };

    try {
      await userStore.sendMetaEvent("search", payload);
    } catch (error) {
      console.error("Error sending event:", error);
    }
  };

  const handleSearch = (
    isAdvancedFilter = isAdvancedFilterApplied,
    event?: any
  ) => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);

    let selectedDomainsIds: any[] = [];
    if (courseStore?.domains && courseStore?.domains.length > 0) {
      courseStore?.domains.forEach((domain: any) => {
        if (selectedDomains.includes(domain.name)) {
          selectedDomainsIds.push(domain.id.toString());
        }
      });
    }

    let searchParams: any = {
      search: search,
      location: placeId,
      categoryId: category,
      domain: selectedDomainsIds,
    };

    if (isAdvancedFilter) {
      searchParams.difficulty = difficulty;
      searchParams.selectedDifficulties = selectedDifficulties;
      searchParams.priceMin = priceRange[0];
      searchParams.priceMax = priceRange[1];
    }

    courseStore
      ?.searchCourses(8, searchParams)
      .then(() => {
        let searchStorage: any = {};
        if (search && search !== "") {
          searchStorage.keyword = search;
        }
        if (selectedDomains && selectedDomains.length > 0) {
          searchStorage.domains = selectedDomains;
        }
        if (placeId && placeId !== "" && location && location !== null) {
          searchStorage.location = {
            placeId: placeId,
            locationName: location,
          };
        }
        if (isAdvancedFilter) {
          if (selectedDifficulties.length) {
            searchStorage.selectedDifficulties = selectedDifficulties;
          }
          if (difficulty && difficulty !== "") {
            searchStorage.difficulty = difficulty;
          }
          if (priceRange && priceRange.length === 2) {
            searchStorage.priceMin = priceRange[0];
            searchStorage.priceMax = priceRange[1];
          }
        }
        sessionStorage.setItem("search", JSON.stringify(searchStorage));
        if (Object.keys(searchStorage).length > 0) {
          sendSearchEvent(searchStorage);
        }
        navigate("/search");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPlaceSelected = (value: any | null) => {
    setLocation(value);
    if (value?.value?.place_id) {
      setPlaceId(value.value.place_id);
    } else {
      setPlaceId(null);
    }
  };

  const clearLocation = () => {
    setLocation(null);
    setPlaceId(null);

    let searchStorage = sessionStorage.getItem("search");
    if (searchStorage) {
      let searchStorageObject = JSON.parse(searchStorage);
      if (searchStorageObject.location) {
        delete searchStorageObject.location;
        sessionStorage.setItem("search", JSON.stringify(searchStorageObject));
      }
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    resetChanges();
  };

  const resetChanges = () => {
    setDifficulty(t("searchBar.intermediate"));
    setSelectedDifficulties([]);
    setPriceRange([30, 1000]);
    setIsAdvancedFilterApplied(false);
  };

  const applyFilter = () => {
    let newFilterState = true;
    setIsAdvancedFilterApplied(newFilterState);
    handleSearch(newFilterState);
    setIsPopupOpen(false);
  };

  const openMobilePopup = () => {
    if (window.innerWidth < 640) {
      setIsPopupOpen(false);
      setIsMobilePopupOpen(true);
    }
  };

  const handlePriceInputChange = (index: number, value: number) => {
    const newPriceRange = [...priceRange];
    newPriceRange[index] = value;

    setPriceRange(newPriceRange);

    if (newPriceRange[0] > newPriceRange[1]) {
      setIsApplyDisabled(true);

      if (index === 0) {
        setMinError(true);
        setMaxError(false);
      } else {
        setMaxError(true);
        setMinError(false);
      }
    } else {
      setIsApplyDisabled(false);
      setMinError(false);
      setMaxError(false);
    }

    setIsApplyDisabled(newPriceRange[0] > newPriceRange[1]);
  };

  const handleDifficultySelection = (diff: string) => {
    if (selectedDifficulties.includes(diff)) {
      setSelectedDifficulties(selectedDifficulties.filter((d) => d !== diff));
    } else {
      setSelectedDifficulties([...selectedDifficulties, diff]);
    }
  };

  const getLocationAndSave = async () => {
    if (localStorage.getItem("userLocation")) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await fetch(
              `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
            );
            const data = await response.json();

            localStorage.setItem("userLocation", JSON.stringify(data));
          } catch (error) {
          } finally {
          }
        },
        (error) => {
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <div className="relative flex items-center border border-primary-gray-100 rounded-full bg-primary-gray-50 w-[350px] sm:w-[640px] h-[65px]">
        <div className="flex justify-center items-center rounded-l-full bg-primary-paradise-pink-100 size-[65px]">
          <img
            className="cursor-pointer"
            src="/images/icons/filter-menu.svg"
            alt=""
            onClick={() => setIsPopupOpen(true)}
          />
        </div>

        <div className="py-1 px-1.5" onClick={openMobilePopup}>
          <div
            className={`text-primary-paradise-pink-850 px-5 py-1 rounded-xl ${
              dropdown
                ? "border border-primary-paradise-pink-200 bg-primary-paradise-pink-50"
                : "border border-white"
            }`}
            onClick={() => {setDropdown(true); getLocationAndSave()}}
            ref={dropdownRef}
          >
            <label
              className={`font-inter font-medium text-xs leading-6 block  ${
                dropdown ? "text-primary-paradise-pink-500" : "text-[#1B0209]"
              }`}
              htmlFor="course"
            >
              {t("searchBar.course")}
            </label>
            <input
              className="body-small bg-transparent focus:outline-none focus:ring-0 placeholder-[#76676B]"
              type="text"
              id="course"
              placeholder={t("searchBar.searchPlaceholder") || ""}
              autoComplete="off"
              value={
                focus
                  ? search
                  : `${search}${
                      search && selectedDomains.length > 0 ? ", " : ""
                    }${selectedDomains.join(", ")}`
              }
              onChange={(e) => setSearch(e.target.value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />

            {dropdown && (
              <ul
                className={`absolute top-[65px] left-[65px] border border-primary-gray-100 p-2.5 w-1/2 z-20 space-y-1 bg-primary-gray-50 custom_scrollbar`}
                style={{
                  boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
                  maxHeight: "300px",
                  overflowY: "scroll",
                  listStyleType: "none",
                }}
              >
                {selectedDomains.map((domain, index) => (
                  <ListItem
                    key={`selected-${index}`}
                    name={domain}
                    icon={
                      env.IMAGE_API +
                        domainsIcons.find(
                          (item: any) => item.domain.name === domain
                        )?.icon || icons[icons.length - 1]
                    }
                    selectedItems={selectedDomains}
                    setSelectedItems={setSelectedDomains}
                    setFocus={setFocus}
                    //setSearch={setSearch}
                  />
                ))}
                {categories
                  .filter(
                    (courseItem: any) =>
                      !selectedDomains.includes(courseItem.name) &&
                      courseItem.name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  .map((course: any, index: number) => (
                    <ListItem
                      key={index}
                      name={course.name}
                      icon={env.IMAGE_API + course.icon}
                      selectedItems={selectedDomains}
                      setSelectedItems={setSelectedDomains}
                      setFocus={setFocus}
                      //setSearch={setSearch}
                    />
                  ))}
                {search &&
                  !categories.some(
                    (courseItem: any) =>
                      courseItem.name.toLowerCase() === search.toLowerCase()
                  ) &&
                  !selectedDomains.includes(search) && (
                    <ListItem
                      key="new-course"
                      name={search}
                      icon={""}
                      selectedItems={selectedDomains}
                      setSelectedItems={setSelectedDomains}
                      setFocus={setFocus}
                      isDisabled={true}
                      //setSearch={setSearch}
                    />
                  )}
              </ul>
            )}
          </div>
        </div>

        <span className="hidden sm:block h-[34px] w-[1px] mx-2.5 bg-black bg-opacity-10" />
        <div className="hidden sm:block py-1 px-1.5">
          <div className="text-primary-paradise-pink-850 px-5 py-1 rounded-xl">
            <label
              className="font-inter font-medium text-xs leading-6 block text-[#1B0209]"
              htmlFor="location"
            >
              {t("searchBar.location")}
            </label>
            <div className="relative" onClick={() => {getLocationAndSave()}}>
              <GooglePlacesAutocomplete
                apiKey={env.GOOGLE_MAPS_API_KEY}
                selectProps={{
                  onChange: onPlaceSelected,
                  value: location,
                  placeholder: t("searchBar.locationPlaceholder"),
                  noOptionsMessage: () => t("searchBar.locationsNearYou"),
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      width: "100%",
                      padding: "0",
                      margin: "0",
                    }),
                    indicatorsContainer: (provided) => ({
                      display: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: "100%",
                      padding: "0",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      width: "100%",
                      padding: "0",
                      cursor: "text",
                      margin: "0",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "200px",
                      border: "none",
                      padding: "0",
                      margin: "0",
                      minHeight: "26px",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                      fontWeight: "400",
                      width: "100%",
                      padding: "0",
                      margin: "0",
                      color: "#76676B",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      padding: "0",
                      margin: "0",
                    }),
                  },
                  className: "body-small w-full",
                }}
              />
              {location && (
                <img
                  onClick={clearLocation}
                  src="images/icons/close-button.svg"
                  alt="close"
                  height="24px"
                  width="24px"
                  className="absolute right-0 top-0  pt-1 px-1 text-red-500 hover:cursor-pointer"
                />
              )}
            </div>
          </div>
        </div>

        <div
          onClick={(e) => {
            handleSearch(isAdvancedFilterApplied, e);
          }}
          className="hidden sm:flex justify-center items-center ml-auto mr-2 rounded-full size-[50px] bg-primary-paradise-pink-500 cursor-pointer"
        >
          <img src="/images/icons/search.svg" alt="" />
        </div>
      </div>

      {isMobilePopupOpen && (
        <SearchModal
          closePopup={() => setIsMobilePopupOpen(false)}
          difficulty={difficulty}
          price={priceRange}
          setActualPlaceId={setPlaceId}
          setSelectedDomains={setSelectedDomains}
          setLocationLabel={setLocation}
          setDifficulty={setDifficulty}
          setPrice={setPriceRange}
          setFocus={setFocus}
        />
      )}

      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        style={modalStyles}
      >
        <div className="w-[350px] sm:w-[464px]">
          <div className="flex itmes-center justify-between border-b border-primary-gray-100 p-5">
            <div className="body-medium-bold text-primary-purple-800">
              {t("searchBar.filter")}
            </div>
            <img
              className="cursor-pointer"
              src="/images/icons/close-black.svg"
              alt=""
              onClick={closePopup}
            />
          </div>
          <div className="flex flex-col gap-6 p-5">
            <div className="body-small">
              <div className="text-primary-paradise-pink-800 mb-1">
                {t("searchBar.difficulty")}
              </div>
              <div className="flex flex-wrap gap-1.5 text-primary-paradise-pink-500">
                {difficulties.map((diff) => (
                  <button
                    key={diff}
                    className={`px-7 py-2.5 rounded-xl cursor-pointer border ${
                      selectedDifficulties.includes(diff)
                        ? "bg-primary-paradise-pink-100 border-primary-paradise-pink-500"
                        : "bg-primary-paradise-pink-50 border-primary-paradise-pink-100"
                    }`}
                    onClick={() => handleDifficultySelection(diff)}
                  >
                    {diff}
                  </button>
                ))}
              </div>
            </div>
            <div className="body-small">
              <div className="text-primary-paradise-pink-800 mb-1">
                {t("searchBar.priceRange")}
              </div>
              <div className="flex flex-col gap-1.5">
                <div className="caption-text flex justify-between text-primary-gray-500">
                  <span>{t("searchBar.minimum")}</span>
                  <span>{t("searchBar.maximum")}</span>
                </div>
                <div className="caption-text-bold flex justify-between text-primary-paradise-pink-850">
                  <div className="px-4 py-2 border border-primary-gray-200 rounded-xl">
                    <span>USD</span>
                    <input
                      className="body-small text-primary-gray-800 w-[40px] ml-4"
                      type="number"
                      min={MIN_PRICE}
                      max={priceRange[1]}
                      value={priceRange[0]}
                      onChange={(e) =>
                        handlePriceInputChange(
                          0,
                          parseInt(e.target.value) || MIN_PRICE
                        )
                      }
                    />
                  </div>
                  <div className="px-4 py-2 border border-primary-gray-200 rounded-xl">
                    <span>USD</span>
                    <input
                      className="body-small text-primary-gray-800 w-[40px] ml-4"
                      type="number"
                      min={priceRange[0]}
                      max={MAX_PRICE}
                      value={priceRange[1]}
                      onChange={(e) =>
                        handlePriceInputChange(
                          1,
                          parseInt(e.target.value) || MAX_PRICE
                        )
                      }
                    />
                  </div>
                </div>
                <div className="py-[15px]">
                  <Slider
                    className="slider"
                    min={MIN_PRICE}
                    max={MAX_PRICE}
                    value={priceRange}
                    onChange={(newRange) => {
                      setPriceRange(newRange);
                      setIsApplyDisabled(newRange[0] > newRange[1]);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center gap-5 mt-20">
              <div
                className="body-small-linked cursor-pointer"
                onClick={resetChanges}
              >
                {t("searchBar.resetChanges")}
              </div>
              <Button
                onClick={applyFilter}
                text={t("searchBar.applyFilter")}
                type="primary_dark"
                width="fit-content"
                isDisabled={isApplyDisabled}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "12px",
    border: "1px solid var(--Primary-Gray-100, rgba(245, 245, 245, 1))",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "var(--Primary-Gray-50)",
    // margin: "20px",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: "30",
  },
};
