import { observer } from "mobx-react";
import ContentItem from "./ContentItem";
import { useStore } from "../../../../store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function CourseContent() {
  const { courseStore } = useStore();
  const { t } = useTranslation();

  const [expandAll, setExpandAll] = useState(false);

  const course: any = courseStore.courseDetails;

  return (
    <div className="flex flex-col gap-4 w-full" id="course-content">
      <div className="flex items-center justify-between">
        <h3 className="body-large-bold">Course Content</h3>
        <div
          className="body-medium underline text-primary-paradise-pink-500 hover:text-primary-paradise-pink-600 cursor-pointer"
          onClick={() => setExpandAll(!expandAll)}
        >
          {expandAll ? t("messages.collapse") : t("messages.expand")}{" "}
          {t("messages.all")}
        </div>
      </div>

      <div>
        {course?.isOldSyllabus
          ? course?.syllabus?.blocks?.map((item: any, index: number) =>
              item.type === "unstyled" ? (
                <ContentItem
                  id={index}
                  key={index}
                  expanded={expandAll}
                  course={item}
                  isOldSyllabus
                />
              ) : null
            )
          : course?.syllabus?.map((item: any, index: number) => (
              <ContentItem
                key={index}
                id={index}
                expanded={expandAll}
                course={item}
              />
            ))}
      </div>
    </div>
  );
}

export default observer(CourseContent);
