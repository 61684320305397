import { observer } from "mobx-react";
import { Button, Input } from "src/components/shared";
import { validateName } from "../../../../utils/validation";
import { useState } from "react";
import { useStore } from "../../../../store";
import LocationInput from "src/components/shared/Input/LocationInput";
import { useTranslation } from "react-i18next";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  user: any;
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function NameAndLocation({ user, setUser, setStep }: IProps) {
  const { userStore } = useStore();
  const { t } = useTranslation(); 


  const [fullName, setFullName] = useState<string>("");
  const [country, setCountry] = useState<any>();
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const handleNextStep = async () => {    
    setIsButtonClicked(true);
  
    const result = await userStore.updateProfile(user.id, {
      fullName,
      country: country.address.name,
    });
    if (result) {
      setUser((user: any) => ({
        ...user,
        fullName,
        country: country.address.name,
      }));
      setStep(5);
    }
    setIsButtonClicked(false);
    HubSpotsignupStore.setFullName(fullName);
    HubSpotsignupStore.setLocation(country.address.name);
  };

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <span className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large">
        {t("accountInfo.heading")}
        </span>
        <span className="text-body-small sm:text-body-medium">
        {t("accountInfo.subheading")}
        </span>
      </div>

      <div className="flex flex-col gap-3.5 w-full">
        <Input
          type="text"
          label={t("accountInfo.fullNameLabel")}
          required
          value={fullName}
          placeholder={t("accountInfo.fullNamePlaceholder")}
          validation={validateName}
          onChange={(e) => setFullName(e.target.value)}
        />
        <LocationInput
          value={country}
          setValue={setCountry}
          required
          label={t("accountInfo.locationLabel")}
          placeholder={t("accountInfo.locationPlaceholder")}
        />
      </div>

      <Button
        type="primary"
        text={t("accountInfo.continue")}
        onClick={handleNextStep}
        isDisabled={!validateName(fullName) || !country || isButtonClicked}
      />
    </>
  );
}

export default observer(NameAndLocation);
