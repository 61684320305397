import React, { ChangeEventHandler } from "react";

interface valueType {
  id: number;
  name: string;
}

interface InputProps {
  label: string;
  value?: valueType;
  options: valueType[];
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  setValue: (value: valueType) => void;
}

const Select: React.FC<InputProps> = ({
  label,
  options,
  value,
  disabled,
  required,
  placeholder,
  setValue,
}) => {
  const [selected, setSelected] = React.useState(false);

  const handleOptionSelection = (option: any) => {
    setSelected(false);
    setValue(option);
  };

  return (
    <div className="flex flex-col gap-0.5 w-full">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div
        className={`body-small flex items-center justify-between gap-2 px-4 py-3 bg-primary-gray-50 text-primary-gray-800 outline rounded-xl hover:outline-primary-paradise-pink-500 cursor-pointer ${
          selected
            ? "outline-2 outline-primary-paradise-pink-500"
            : "outline-1 outline-primary-gray-200"
        }`}
        onClick={() => setSelected(!selected)}
      >
        <span>{value?.name || placeholder}</span>
        <img
          src={
            selected
              ? "/images/icons/arrow-up-purple.svg"
              : "/images/icons/arrow-down-purple.svg"
          }
        />
      </div>
      <div
        className={`custom_scrollbar mt-2 rounded-xl overflow-hidden overflow-y-scroll transition-all duration-300 ${
          selected ? "opacity-100 max-h-[200px]" : "opacity-0 max-h-0"
        }`}
        style={{ boxShadow: "rgba(0, 0, 0, 0.14) 0px 4px 10px -2px" }}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className={`body-small px-6 py-3 cursor-pointer hover:text-primary-paradise-pink-500 ${
              value?.id === option.id
                ? "text-primary-paradise-pink-500 bg-primary-paradise-pink-50"
                : "bg-primary-gray-50"
            }`}
            onClick={() => handleOptionSelection(option)}
          >
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
