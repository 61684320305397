import { observer } from "mobx-react";
import { Button } from "src/components/shared";
import styles from "../../auth.module.css";
import { useEffect, useState } from "react";
import { useStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  user: any;
  setUser: (user: any) => void;
}

function ChoosePassions({ user, setUser }: IProps) {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();
  const { t } = useTranslation();
  // let passionsList:any[] = [];

  const [passions, setPassions] = useState<string[]>([]);
  const [isRegisterClicked, setIsRegisterClicked] = useState<boolean>(false);
  const [passionsList, setPassionsList] = useState<string[]>([]);

  useEffect(() => {
    courseStore.getCategories();
    courseStore.getAllDomains().then((response) => {
      if (response) {        
        setPassionsList(response.map((category: any) => category.name))
      }
    });
  }, []);

  const handlePassionSelection = (passion: string) => {
    if (passions.includes(passion)) {
      setPassions(passions.filter((p) => p !== passion));
    } else if (passions.length < 3) {
      setPassions([...passions, passion]);
    }
  };

  const handleSkip = async () => {
    setPassions([]);
    setUser({ ...user, interest: [] });
    await handleRegister([]);
  };

  const handleRegister = async (interrests: string[]) => {
    setIsRegisterClicked(true);
    const result = await userStore.updateProfile(user.id, { interrests });
    if (result) {
      setUser((user: any) => ({ ...user, interest: interrests }));
      HubSpotsignupStore.setInterests(interrests);
      HubSpotsignupStore.submitToHubspot();

      // localStorage.removeItem("registrationUser");
      // localStorage.removeItem("registrationStep");
      if (courseStore.selectedCourse) {
        navigate(`/payment/${courseStore.selectedCourse}`);
      } else {
        toast.success("Your account is created successfully.");
        navigate("/");
      }
    } else {
      toast.error("Something went wrong!");
    }
    setIsRegisterClicked(false);
  };

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <span className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large">
        {t("choosePassions.heading")}
        </span>
        <span className="text-body-small sm:text-body-medium">
        {t("choosePassions.subheading")}
        </span>
      </div>

      <div className="flex flex-col gap-8 w-full">
        <div className="flex flex-wrap gap-1.5 items-center justify-start">
          {passionsList.map((passion, index) => (
            <button
              key={index}
              className={`large-button border rounded-full px-8 py-3 transition duration-100 ease-in ${
                passions.includes(passion)
                  ? styles.selected_domain
                  : styles.unselected_domain
              }`}
              onClick={() => handlePassionSelection(passion)}
            >
              <span>{passion}</span>
            </button>
          ))}
        </div>

        <div className="flex gap-1.5">
          <Button
            type="secondary"
            text={t("choosePassions.skip")}
            onClick={handleSkip}
            isDisabled={isRegisterClicked}
          />
          <Button
            type="primary"
            text={t("choosePassions.continue")}
            isDisabled={passions.length === 0 || isRegisterClicked}
            onClick={() => handleRegister(passions)}
          />
        </div>
      </div>
    </>
  );
}

export default observer(ChoosePassions);
