import React, { useState } from "react";
import { Input } from "..";

interface InputProps {
  value: any;
  label: string;
  required?: boolean;
  placeholder?: string;
  setValue: (value: any) => void;
}

const LocationInput: React.FC<InputProps> = ({
  value,
  label,
  required,
  placeholder,
  setValue,
}) => {
  const [predictions, setPredictions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (input: string) => {
    setInputValue(input);
    if (!input) {
      setPredictions([]);
      return;
    }

    const autocompleteService = new google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions({ input }, (predictionsList) => {
      if (predictionsList) {
        setPredictions(predictionsList);
      } else {
        setPredictions([]);
      }
    });
  };

  const handlePlaceSelection = (prediction: any) => {
    setInputValue(prediction.description);
    setPredictions([]);

    const placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    placesService.getDetails(
      { placeId: prediction.place_id },
      (place, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place &&
          place.geometry &&
          place.geometry.location
        ) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setValue({
            address: {
              name: prediction.description,
              area: prediction.description,
              city: prediction.description,
              state: prediction.description,
            },
            location: { lat, lng },
          });
          // console.log(`Latitude: ${lat}, Longitude: ${lng}`);
        }
      }
    );
  };

  return (
    <div className="relative">
      <Input
        type="text"
        label={label}
        required={required}
        value={inputValue}
        placeholder={placeholder}
        icon="/images/icons/location-black.svg"
        onChange={(e) => handleInputChange(e.target.value)}
      />

      {predictions.length > 0 && (
        <div
          className="absolute mt-2 w-full z-10 custom_scrollbar caption-text flex flex-col gap-2 px-5 py-3 max-h-[157px] bg-primary-gray-50 border border-primary-gray-100 rounded-xl"
          style={{ boxShadow: "0px 12px 16px -4px rgba(0, 0, 0, 0.1)" }}
        >
          {predictions.map((prediction, index) => (
            <div
              className="flex gap-3 cursor-pointer hover:text-primary-paradise-pink-500"
              key={index}
              onClick={() => handlePlaceSelection(prediction)}
            >
              <img src="/images/icons/location-black.svg" />
              {prediction.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
