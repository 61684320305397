import React, { useEffect } from "react";

import styles from "./item.module.css";

interface IProps {
  course: any;
  expanded: boolean;
  isOldSyllabus?: boolean;
}

export default function ContentItem({
  course,
  expanded,
  isOldSyllabus,
}: IProps) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <>
      {isOldSyllabus && (
        <div className={styles.chapter}>
          <div
            className={styles.chapter_header}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <img
              width={24}
              height={24}
              src={
                isExpanded
                  ? "/images/icons/minus-primary.svg"
                  : "/images/icons/plus-primary.svg"
              }
              alt="Arrow down"
            />

            <span className={styles.chapter_header_title}>{course?.text}</span>
            <span className={styles.chapter_header_details}>
              {/* 4 Sections • 80 minutes */}
            </span>
          </div>

          {/* {isExpanded && (
                  <div className={styles.chapter_body}>
                      {course.lessons.map((lesson) => (
                          <div key={lesson.id} className={styles.chapter_body_item}>
                              <img
                                  width={24}
                                  height={24}
                                  src="/images/icons/article.svg"
                                  alt="Article icon"
                              />
                              <span className={styles.chapter_body_item_title}>{lesson.title}</span>
                              <span className={styles.chapter_body_item_duration}>{lesson.duration} minutes</span>
                          </div>
                      ))}
                  </div>
              )} */}
        </div>
      )}

      {!isOldSyllabus && (
        <div className={styles.chapter}>
          <div
            className={styles.chapter_header}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <img
              width={24}
              height={24}
              src={
                isExpanded
                  ? "/images/icons/minus-primary.svg"
                  : "/images/icons/plus-primary.svg"
              }
              alt="Arrow down"
            />

            <span className={styles.chapter_header_title}>{course?.title}</span>
            <span className={styles.chapter_header_details}>
              {/* 4 Sections • 80 minutes */}
            </span>
          </div>

          {isExpanded && (
            <div className={styles.chapter_body}>
              <div className={styles.chapter_body_item}>
                {/* <img
                  width={24}
                  height={24}
                  src="/images/icons/article.svg"
                  alt="Article icon"
                /> */}
                <div
                  className={styles.chapter_body_item_title}
                  dangerouslySetInnerHTML={{ __html: course?.content }}
                />
                {/* <span className={styles.chapter_body_item_title}>
                  {course?.content}
                </span> */}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
