import { observer } from "mobx-react";

import styles from "./content.module.css";
import ContentItem from "./ContentItem";
import { useStore } from "../../../../store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function CourseContent() {
  const { courseStore } = useStore();
  const { t } = useTranslation();

  const [expandAll, setExpandAll] = useState(false);

  const course: any = courseStore.courseDetails;

  return (
    <div className={styles.course_content} id="course-content">
      <div className={styles.course_content_content}>
        {/* <div className={styles.course_content_info}>
          <h4 className={styles.course_content_subtitle}>
            {t("messages.courseContentTitle")}
          </h4>
          <h3 className={styles.course_content_title}>
            {t("messages.courseContentText")}
          </h3>
        </div> */}

        <div className={styles.course_summary}>
          <div className={styles.numbers}>
            {/* {course?.syllabus?.blocks?.length} Lessons
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length} Sessions
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length} Articles
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length} Assignments
                        <img src="/images/icons/dot.svg" alt="dot" /> 
                        {course?.syllabus?.blocks?.length}h Compition Time */}
          </div>

          <div
            className={styles.expand_all}
            onClick={() => setExpandAll(!expandAll)}
          >
            {expandAll ? t("messages.collapse") : t("messages.expand")}{" "}
            {t("messages.allLessons")}
          </div>
        </div>

        {course?.isOldSyllabus && (
          <div className={styles.course_content_items}>
            {course?.syllabus?.blocks?.map((item: any, index: number) =>
              item.type === "unstyled" ? (
                <ContentItem key={index} expanded={expandAll} course={item} isOldSyllabus />
              ) : null
            )}
          </div>
        )}
        {!course?.isOldSyllabus && (
          <div className={styles.course_content_items}>
            {course?.syllabus?.map((item: any, index: number) => (
              <ContentItem key={index} expanded={expandAll} course={item} />
            ))}
          </div>
        )

        }
      </div>
    </div>
  );
}

export default observer(CourseContent);
