import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import {
  NewCourseComponents as CourseComponents,
  Footer,
  Navbar,
  Loading,
} from "../../../components";
import { useStore } from "../../../store";
import Container from "src/components/shared/Container";
import { toast, ToastContainer } from "react-toastify";

function Course() {
  const params = useParams();
  const { courseStore, userStore } = useStore();
  const course: any = courseStore.courseDetails;

  const [loading, setLoading] = useState<boolean>(true);

  const sendCourseiewEvent = async () => {
    if (!params.id) return;

    const payload = {
      event_name: "course_view",
      event_time: Math.floor(Date.now() / 1000),
      event_source_url: window.location.href,
      action_source: "website",
      user_data: {
        client_user_agent: navigator.userAgent,
        em: [userStore.user?.email || ""],
        ph: [userStore.user?.phone || ""],
        country: userStore.user?.country || "",
        ge: userStore.user?.gender || "",
        fn: userStore.user?.fullName || "",
        ln: userStore.user?.fullName || "",
      },
      custom_data: {
        course_id: params.id,
        course_name: course?.title || "Unknown Course",
      },
    };

    try {
      await userStore.sendMetaEvent("course_view", payload);
    } catch (error) {
      console.error("Error sending event:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      if (params.id) {
        await courseStore.getCourseById(String(params.id));
        await courseStore.lookForSessions(params.id);
        sendCourseiewEvent();
      }
      await userStore.getUser();
    } catch (error) {
      toast.error("Error loading course data");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar fixed={false} />
      <ToastContainer />

      <div className="relative w-full flex flex-col justify-start items-start gap-5 sm:gap-10">
        <div className="block sm:hidden sticky top-0 z-50 w-full">
          <CourseComponents.CourseNavBar />
        </div>
        <Container>
          <div className="flex sm:gap-4 lg:gap-[50px] sm:py-5 sm:pt-10 sm:px-[2rem] lg:px-[7.5%]">
            <div className="flex flex-col gap-5 sm:w-2/3">
              <div className="lg:w-3/4">
                <CourseComponents.CourseHeader type="course" />
              </div>
              <div className="hidden sm:block sticky top-0">
                <CourseComponents.CourseNavBar />
              </div>
              <div className="flex flex-col gap-[30px] sm:gap-[60px]">
                <CourseComponents.AboutCourse />
                <CourseComponents.AboutCreator />
                <CourseComponents.CourseContent />
              </div>
            </div>
            <div className="hidden sm:block sm:w-1/3 h-fit sticky top-[67px]">
              <CourseComponents.CourseUpcomingClasses />
            </div>
          </div>
        </Container>

        <div className="fixed bottom-0 left-0 block sm:hidden w-full z-10">
          <CourseComponents.CourseUpcomingClassesMobile />
        </div>

        <div className="w-full m-0 bg-primary-purple-50">
          <Container>
            <div className="sm:px-[2rem] lg:px-[7.5%]">
              <CourseComponents.CourseReviews />
            </div>
          </Container>
        </div>

        <Container>
          <div className="sm:px-[2rem] lg:px-[7.5%]">
            <div className="lg:px-[200px]">
              <CourseComponents.FAQs />
            </div>
          </div>
        </Container>

        <div className="w-full">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default observer(Course);
