import { observer } from "mobx-react";
import { Button, Input } from "src/components/shared";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../../../utils/validation";
import { useState } from "react";
import { useStore } from "../../../../store";
import { useTranslation } from "react-i18next";

interface IProps {
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function PasswordInput({ setUser, setStep }: IProps) {
  const { userStore } = useStore();
  const { t } = useTranslation();


  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const handleNextStep = async () => {
    setIsButtonClicked(true);
    const result = await userStore.updatePassword(password);
    if (result) {
      setUser((user: any) => ({ ...user, password, id: result.userId }));
      setStep(4);
    }
    setIsButtonClicked(false);
  };

  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        <span className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large">
        {t("passwordInput.heading")}
        </span>
        <span className="text-body-small sm:text-body-medium">
        {t("passwordInput.subheading")}

        </span>
      </div>

      <div className="flex flex-col gap-3.5 w-full">
        <Input
          type="password"
          label={t("passwordInput.passwordLabel")}
          required
          value={password}
          placeholder={t("passwordInput.enterPasswordPlaceholder")}
          validation={validatePassword}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          label={t("passwordInput.confirmPasswordLabel")}
          required
          value={confirmPassword}
          placeholder={t("passwordInput.enterPasswordPlaceholder")}
          validation={() => validateConfirmPassword(password, confirmPassword)}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>

      <Button
        type="primary"
        text={t("passwordInput.continueButton")}
        onClick={handleNextStep}
        isDisabled={
          !validatePassword(password) ||
          !validateConfirmPassword(password, confirmPassword) ||
          isButtonClicked
        }
      />
    </>
  );
}

export default observer(PasswordInput);
