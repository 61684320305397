import { observer } from "mobx-react";
import { Button } from "../../../../components";
import { useNavigate } from "react-router-dom";

function VerificationSent() {
  const navigate = useNavigate();

  const handleNextStep = async () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center gap-6 sm:gap-8 p-6 sm:px-20 sm:py-10 w-full border border-black border-opacity-5 rounded-xl">
      <img src="/images/icons/ok.svg" className="size-[90px] sm:size-[120px]" />
      <div className="flex flex-col gap-0.5 sm:gap-2 text-center">
        <h3 className="text-body-medium font-body-medium-bold sm:text-body-large sm:font-body-large">
          Verification Request Sent!
        </h3>
        <p className="text-body-small sm:text-body-medium">
          Thank you! We’ve received your details. Our team will review your
          application and notify you via email about your status within a few
          days.
        </p>
      </div>
      <div className="w-full sm:w-fit">
        <Button
          type="primary_dark"
          text="Return to Home"
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
}

export default observer(VerificationSent);
