import React from "react";
import { observer } from "mobx-react";
import { useBetaNotification } from "src/store/useBetaNotification";

const Beta: React.FC = () => {
  const { isBetaVisible, hideBeta } = useBetaNotification();

  if (!isBetaVisible) return null;
  return (
    <div className="fixed top-0 w-full h-12 flex items-center justify-center p-4 z-50 bg-primary-purple-850">
      <p className="text-white text-sm font-inter font-normal">
        We’re in Beta, your{" "}
        <span className="text-primary-purple-400 underline cursor-pointer">
          feedback
        </span>{" "}
        matters to us!
      </p>
      <img
        className="size-6 right-4 sm:absolute cursor-pointer"
        src="/images/icons/close-white.svg"
        alt=""
        onClick={hideBeta}
      />
    </div>
  );
};

export default observer(Beta);
