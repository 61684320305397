import React, { useEffect, useRef } from "react";

import styles from "./cohorts.module.css";
import { format } from "date-fns";
import { Button, Popup } from "src/components";
import { useStore } from "../../../../store";
import env from "../../../../config/env";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../utils/formatCurrency";

interface IProps {
  courseId: string | undefined;
}

function UpcomingCohorts({ courseId }: IProps) {
  const navigate = useNavigate();
  const { userStore, courseStore } = useStore();
  const { t } = useTranslation();

  const [sessions, setSessions] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const cohortListRef = useRef<HTMLDivElement>(null);
  const cohortDetailsRef = useRef<HTMLDivElement>(null);
  const cohortDetailsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      cohortListRef.current &&
      cohortDetailsRef.current &&
      cohortDetailsContainerRef.current
    ) {
      cohortDetailsRef.current.style.height = `${cohortDetailsContainerRef.current.offsetHeight}px`;
      cohortListRef.current.style.height = `${cohortDetailsRef.current.offsetHeight}px`;
    }
  }, [cohortDetailsRef.current?.offsetHeight, selected]);

  const loadUpcomingCohorts = async () => {
    if (courseId) {
      try {
        await courseStore.lookForSessions(courseId);
        console.log(courseStore.searchedCourses);
        setSessions(courseStore.searchedCourses);
        setSelected(courseStore.searchedCourses[0]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    loadUpcomingCohorts();
  }, [courseId]);

  const formatDate = (date: string | undefined) => {
    if (!date) return "";
    return format(new Date(date), "dd MMMM yyyy");
  };

  const redirectToSession = (sessionId: any) => {
    if (sessionId) {
      navigate(`/class/${sessionId}`);
    }
  };

  const participate = async () => {
    courseStore.selectCourse(selected?.id);

    if (userStore.user) {
      navigate(`/payment/${selected?.id}`);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.cohort_container} id="upcoming-cohorts">
      <div className={styles.cohort_info}>
        <h4 className={styles.cohort_subtitle}>{t("messages.whereToJoin")}</h4>
        <h3 className={styles.cohort_title}>
          {t("messages.courseUpcomingCohorts")}
        </h3>
      </div>
      {sessions.length > 0 ? (
        <div className={styles.cohort}>
          <div className={styles.cohort_list} ref={cohortListRef}>
            {sessions.map((session, index) => (
              <div
                key={index}
                className={`
                                ${styles.cohort_item}
                                ${
                                  selected?.id === session.id
                                    ? styles.cohort_item_selected
                                    : ""
                                }
                            `}
                onClick={() => {
                  setSelected(session);
                }}
              >
                <img
                  src={
                    session?.SessionImage &&
                    Object.keys(session?.SessionImage).length > 0
                      ? `${env.IMAGE_API}${session?.SessionImage?.name}`
                      : "images/default-user.svg"
                  }
                  alt="cohort-img"
                />

                <div className={styles.cohort_item_info}>
                  <h4>{session.title}</h4>
                  <p className="line-clamp-1">{session.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.cohort_details} ref={cohortDetailsRef}>
            <div ref={cohortDetailsContainerRef}>
              <div className="flex justify-center items-center pt-4 pb-4 ">
                <img
                  className="rounded-xl h-[268px] w-[720px] object-cover "
                  style={{ objectPosition: "99% 30%" }}
                  src={
                    selected?.SessionImage &&
                    Object.keys(selected?.SessionImage).length > 0
                      ? `${env.IMAGE_API}${selected?.SessionImage?.name}`
                      : "images/default-user.svg"
                  }
                  alt="cohort-img"
                />
              </div>

              <div className={styles.selected_details}>
                <div className={styles.details_header}>
                  <div className={styles.domains}>
                    {selected?.domains?.map((domain: any, index: number) => (
                      <span key={index}>
                        <span>{domain}</span>
                        {index !== selected?.domains?.length - 1 && (
                          <span> • </span>
                        )}
                      </span>
                    ))}
                  </div>
                  <div className={styles.rating}>
                    <span className={styles.rating_number}>
                      {selected?.rating}
                    </span>
                    <img src="/images/icons/star.svg" alt="star" />
                    <span>({selected?.ratingsCount})</span>
                  </div>
                </div>
                <h4
                  onClick={() => redirectToSession(selected?.id)}
                  className="hover:cursor-pointer"
                >
                  {selected?.title}
                </h4>
                <p className="line-clamp-3">{selected?.description}</p>
                <div className={styles.items}>
                  <img
                    width={24}
                    height={24}
                    src="/images/icons/places.svg"
                    alt=""
                  />
                  <span>
                    {selected?.subscribedNumber} {t("messages.places")}
                  </span>
                </div>
                <div className={styles.items}>
                  <img
                    width={24}
                    height={24}
                    src="/images/icons/clock.svg"
                    alt=""
                  />
                  <span>
                    {formatDate(selected?.start)} - {formatDate(selected?.end)}
                  </span>
                </div>
                <div className={styles.price}>
                <span className={styles.after}>
                  {((selected?.price * (100 - selected?.discount)) / 100) !== 0
                    ? formatCurrency(
                        (selected?.price * (100 - selected?.discount)) / 100,
                        userStore.currency
                      )
                    : "FREE"}
                </span>
                  {selected?.discount !== 0 && (
                    <span className={styles.before}>
                      {selected?.price !== 0 ? selected?.price : "FREE"}
                    </span>
                  )}
                </div>
                <Button
                  type="primary"
                  text="Reserve a seat"
                  rightIcon="/images/icons/arrow-right-white.svg"
                  customStyle={{ width: "100%" }}
                  // onClick={() => navigate(`/payment/${selected?.id}`)}
                  onClick={() => participate()}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className={styles.cohort}>{t("messages.noUpcomingCohorts")}</p>
      )}

      {isOpen && (
        <Popup
          type="class"
          isOpen={isOpen}
          course={selected?.id}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default observer(UpcomingCohorts);
