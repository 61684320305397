import { useState } from "react";
import { observer } from "mobx-react";
import { Button } from "../../../../components";
import { useStore } from "../../../../store";
import FilesInput from "src/components/shared/Input/FilesInput";
import { toast } from "react-toastify";
import { HubSpotsignupStore } from "../../../../store/HubSpotContext";

interface IProps {
  user: any;
  setUser: (user: any) => void;
  setStep: (step: number) => void;
}

function AdditionalDetails({ user, setUser, setStep }: IProps) {
  const { userStore } = useStore();

  const [files, setFiles] = useState<File[]>(user?.files || []);
  const [agree, setAgree] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const handleNextStep = async () => {
    setIsButtonClicked(true);
    try {
      const newUser = { ...user, attatchments: files };
      setUser(newUser);
      if (user.image) {
        await userStore.uploadPicture(user.image).then((response) => {
          newUser.image = response;
        });
      }

      files.map(async (file, index) => {
        await userStore.uploadFile(file).then((response) => {
          newUser.attatchments[index] = response;
        });
      });

      await userStore.updateProfile(userStore.user.id, newUser);

      const response = await userStore.sendUpgradeRequest(
        "teacher",
        userStore.user.id
      );
      if (response) {
        setStep(5);
      } else {
        toast.error("Failed to send upgrade request");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrong");
    }

    setIsButtonClicked(false);
    HubSpotsignupStore.submitToHubspot();
    
  };

  const handleBack = () => {
    setStep(3);
  };

  return (
    <div className="flex flex-col gap-5 sm:gap-6 w-full">
      <h3 className="body-large">Additional Details</h3>
      <div className="flex flex-col gap-4 sm:gap-5">
        <div className="flex gap-4 p-5 sm:px-8 sm:py-6 bg-primary-purple-50 border border-primary-purple-200 rounded-3xl sm:rounded-2xl">
          <img src="/images/icons/info-black.svg" />
          <p className="text-body-small sm:text-body-medium">
            Optional: Attach CV, Portfolio, or any relevant documents.
          </p>
        </div>
        <div className="flex flex-col gap-4 sm:gap-5">
          <FilesInput
            label="Additional Attachments"
            files={files}
            setFiles={setFiles}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 sm:gap-2 text-primary-gray-500">
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="confirm"
            name="confirm"
            checked={confirm}
            onChange={() => setConfirm(!confirm)}
            className="cursor-pointer"
          />
          <label
            htmlFor="confirm"
            className="text-caption-text font-caption-text sm:text-body-small sm:font-body-small cursor-pointer"
          >
            I confirm that the information provided is accurate.
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="agree"
            name="agree"
            checked={agree}
            onChange={() => setAgree(!agree)}
            className="cursor-pointer"
          />
          <label
            htmlFor="agree"
            className="text-caption-text font-caption-text sm:text-body-small sm:font-body-small cursor-pointer"
          >
            I agree to the ClassX{" "}
            <a
              rel="noreferrer"
              href="https://community.classx.tech/classx-instructor-agreement/"
              target="_blank"
              className="text-primary-paradise-pink-500 underline"
            >
              Instructor Terms & Conditions
            </a>
          </label>
        </div>
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          type="pale"
          text="Back"
          width="fit-content"
          onClick={handleBack}
        />
        <div className="w-full sm:w-[173px]">
          <Button
            type="primary_dark"
            text="Submit & Verify"
            onClick={handleNextStep}
            isDisabled={!confirm || !agree || isButtonClicked}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(AdditionalDetails);
