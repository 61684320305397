export const formatCurrency = (amount: number, currency: string): string => {
  const roundedAmount = Math.round(amount);

  switch (currency) {
    case "USD":
      return `$${roundedAmount}`;
    case "EUR":
      return `${roundedAmount}€`;
    case "CAD":
      return `${roundedAmount}$ CAD`;
    case "TND":
      return `${roundedAmount} TND`;
    default:
      return `${roundedAmount} ${currency}`;
  }
};
