import React, { lazy, Suspense } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import './header.css';

const Navbar = lazy(() => import('../../../shared/Navbar'));
const SearchBar = lazy(() =>
  import('./searchBar').then((module) => ({
    default: module.SearchBar,
  }))
);

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="home_page_header">
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar defaultTransparent />
      </Suspense>

      <div className="home_page_header_gradient"></div>

      <div className="flex flex-col items-center gap-10 my-10">
        <div className="flex flex-col justify-center">
        <h1 className="font-inter font-extrabold text-[52px] leading-[64px] tracking-[-0.01em] text-center text-primary-paradise-pink-800 px-3.5">
            {t("header.title1")}{" "}
            <span className="colored_span">{t("header.title2")}</span>
            <br />
            {t("header.title3")}
          </h1>
          <p className="px-3.5 sm:px-0 text-sm font-normal text-primary-gray-400 mt-3.5 text-center">
            {t("header.subtitle1")}{" "}
            <span className="font-bold">{t("header.subtitle2")}</span>{" "}
            <br className="block sm:hidden" />
            {t("header.subtitle3")}
          </p>
        </div>

        <Suspense fallback={<div>Loading Search...</div>}>
          <SearchBar />
        </Suspense>
      </div>
    </div>
  );
};

export default observer(Header);
