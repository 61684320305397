import React, { useState } from "react";

interface InputProps {
  enabled: boolean;
  setEnabled: (state: boolean) => void;
}

const Switch: React.FC<InputProps> = ({ enabled, setEnabled }) => {
  return (
    <div
      className={`w-12 h-7 flex items-center rounded-full p-1 cursor-pointer transition-all duration-300 ${
        enabled
          ? "bg-primary-paradise-pink-500 hover:bg-primary-paradise-pink-600"
          : "bg-primary-gray-200 hover:bg-primary-gray-400"
      }`}
      onClick={() => setEnabled(!enabled)}
    >
      <div
        className={`size-5 bg-white rounded-full shadow-md transform transition-all duration-300 ${
          enabled ? "translate-x-5" : "translate-x-0"
        }`}
      ></div>
    </div>
  );
};

export default Switch;
