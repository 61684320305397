"use client";
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import "../InstructorUpgrade/styles.css";
import "react-international-phone/style.css";
import { useTranslation } from "react-i18next";

import {
  Input,
  Button,
  Navbar,
  DateRange,
  TimeRange,
  UploadImage,
  Loading,
  LocationInput,
} from "../../../components";
import MultiSelectInput from "../../../components/shared/MultiSelectInput";
import { toast, ToastContainer } from "react-toastify";
import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { newClassroom } from "src/store/Classroom";

function NewClassroom() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore } = useStore();

  const [loading, setLoading] = useState(false);
  const [numberOfPlaces, setNumberOfPlaces] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [equipments, setEquipments] = useState([]);
  const [logo, setLogo] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [pricePerHour, setPricePerHour] = useState<number>(0);
  const startTimeInputRef = useRef<any>(null);
  const endTimeInputRef = useRef<any>(null);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    userStore.getUser().then(() => {
      const user = userStore.user;
      if (!user) {
        navigate("login");
      }
    });
  }, []);
  const handleAttachFile = (e: any) => {
    const newFiles: File[] = Array.from(e.target.files);
    const existingFileNames = files.map((file) => file.name);
    const filteredNewFiles = newFiles.filter(
      (file: File) => !existingFileNames.includes(file.name)
    );
    setFiles([...files, ...filteredNewFiles]);
  };
  const triggerAttachFile = (): void => {
    fileUploadRef.current?.click();
  };

  const handleStartTimeFocus = () => {
    if (startTimeInputRef.current) {
      startTimeInputRef.current.showPicker();
      startTimeInputRef.current.focus();
    }
  };

  const handleEndTimeFocus = () => {
    if (endTimeInputRef.current) {
      endTimeInputRef.current.showPicker();
      endTimeInputRef.current.focus();
    }
  };

  const onSave = async () => {
    setLoading(true);

    let from;
    if (startDate) {
      const [startHour, startMinute] = startTime
        ? startTime.split(":")
        : ["07", "00"];
      from = new Date(startDate);
      from.setHours(Number(startHour) + 1, startMinute, 0, 0);
    }

    let to;
    if (endDate) {
      const [endHour, endMinute] = endTime ? endTime.split(":") : ["19", "00"];
      to = new Date(endDate);
      to.setHours(Number(endHour) + 1, endMinute, 0, 0);
    }

    const classroom:any = {
      logo,
      name,
      size: numberOfPlaces,
      description,
      equipments,
      from: from?.toISOString(),
      to: to?.toISOString(),
      // startTime: startTime || "07:00",
      // endTime: endTime || "19:00",
      profileId: userStore.user?.id,
      album: [],
      address: {
        name: address.label,
        area: address.label,
        city: address.label,
        state: address.label,
      },
      location: { lat: address.lat, lng: address.lng },
      pricePerHour: pricePerHour
    };

    files.forEach(async (file) => {
      await userStore.uploadPicture(file).then((response) => {
        classroom.album = classroom.album.concat(response);
      });
    });

    if (logo) {
      await userStore.uploadPicture(logo).then((response) => {
        classroom.logo = response;
      });
    }

    await newClassroom(classroom)
      .then((response) => {
        console.log(response);
        setLoading(false);
        navigate("/classroomsManagement");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      const [integerPart, decimalPart] = value.split(".");
      if (!decimalPart || decimalPart.length <= 2) {
        setPricePerHour(parseFloat(value) || 0);
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <ToastContainer theme="dark" />
      <Navbar />
      <div className="container">
      <h1 className="title">{t("newClassroomPage.heading")}</h1>
      <form>
          <section>
          <h2>{t("newClassroomPage.classroomInfo")}</h2>
          <div className="inputs">
              <div className="left-column">
                <div className="field">
                <label htmlFor="logo">{t("newClassroomPage.logoOptional")}</label>
                <div className="input-field">
                    <UploadImage image={logo} setImage={setLogo} />
                  </div>
                </div>
                <div className="field">
                <label htmlFor="loaction">{t("newClassroomPage.location")}</label>
                <div className="input-field">
                    <div className="input">
                      <LocationInput
                        location={address}
                        setLocation={setAddress}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="places">{t("newClassroomPage.numberOfPlaces")}
                  </label>
                  <div className="input-field">
                    <div className="input">
                      <Input
                        type="number"
                        label=""
                        fullWidth
                        value={numberOfPlaces}
                        onChange={(e) => setNumberOfPlaces(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                <label htmlFor="date">{t("newClassroomPage.availableDate")}</label>
                <div className="input-field">
                    <div className="input">
                      <DateRange
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="field">
                  <label htmlFor="date">Available time</label>
                  <div className="input-field">
                    <div className="input">
                      <TimeRange
                        startTime={startTime}
                        setStartTime={setStartTime}
                        endTime={endTime}
                        setEndTime={setEndTime}
                      />
                    </div>
                  </div>
                </div> */}
                <div
                  className="field-group"
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <div className="field" style={{ flex: 1 }}>
                  <label htmlFor="startTime">{t("newClassroomPage.startHour")}</label>
                  <div className="input-field">
                      <div
                        className="input"
                        onClick={handleStartTimeFocus}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="time"
                          value={startTime}
                          ref={startTimeInputRef}
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{
                            padding: "0.8rem",
                            fontSize: "0.8rem",
                            color: "var(--black)",
                            border: "1px solid #cad4e0",
                            borderRadius: "8px",
                            height: "3rem",
                            width: "100%",
                            cursor: "inherit",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field" style={{ flex: 1 }}>
                  <label htmlFor="endTime">{t("newClassroomPage.finishHour")}</label>
                  <div className="input-field">
                      <div
                        className="input"
                        onClick={handleEndTimeFocus}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <input
                          type="time"
                          value={endTime}
                          ref={endTimeInputRef}
                          onChange={(e) => setEndTime(e.target.value)}
                          style={{
                            padding: "0.8rem",
                            fontSize: "0.8rem",
                            color: "var(--black)",
                            border: "1px solid #cad4e0",
                            borderRadius: "8px",
                            height: "3rem",
                            width: "100%",
                            cursor: "inherit",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div className="field">
                <label htmlFor="name">{t("newClassroomPage.classroomName")}</label>
                <div className="input-field">
                    <div className="input">
                      <Input
                        type="text"
                        label=""
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                <label htmlFor="description">
                    {t("newClassroomPage.description")}
                  </label>
                  <div className="input-field">
                    <textarea
                      className="bio"
                      id="description"
                      placeholder={t("newClassroomPage.tellUs") || ""}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                <label>{t("newClassroomPage.pricePerHour")}</label>
                <div className="input-field">
                    <div className="input">
                      <input
                        type="text"
                        id="pricePerHour"
                        name="pricePerHour"
                        value={pricePerHour}
                        onChange={handlePriceChange}
                        className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-lg sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div className="field">
                  <MultiSelectInput
                    text={t("newClassroomPage.availableEquipments")}
                    name="equipments"
                    onValueChange={setEquipments}
                  />
                </div>

                <div className="field">
                <label>{t("newClassroomPage.classroomPhotos")}</label>
                <div className="input-field" id="files">
                    <div className="files">
                      {files.map((file, index) => (
                        <div className="file" key={index}>
                          <img
                            className="document"
                            src="/images/document.png"
                            alt="doc"
                          />
                          <p>{file.name}</p>
                          <img
                            className="trash"
                            src="/images/trash.png"
                            alt="trash"
                            onClick={() =>
                              setFiles(files.filter((f, i) => i !== index))
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <input
                      className="fileInput"
                      type="file"
                      ref={fileUploadRef}
                      multiple
                      onChange={(e) => handleAttachFile(e)}
                    />
                    <Button
                      text={t("newClassroomPage.attachFile")}
                      type="secondary"
                      width="200px"
                      customColor="rgb(117, 116, 116)"
                      onClick={triggerAttachFile}
                      preventDefault
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="buttons">
            <Button
              text={t("newClassroomPage.cancelButton")}
              type="primary"
              customColor="#373737"
              width="400px"
              preventDefault
              onClick={() => navigate(-1)}
            />
            <Button
              text={t("newClassroomPage.saveButton")}
              type="primary"
              width="400px"
              preventDefault
              onClick={onSave}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
export default observer(NewClassroom);
