import React from "react";

interface InputProps {
  value: number;
  label: string;
  required?: boolean;
  max?: number;
  min?: number;
  setValue: (value: number) => void;
}

const NumberInput: React.FC<InputProps> = ({
  value,
  label,
  required,
  max = 10,
  min = 5,
  setValue,
}) => {
  const handleMinus = () => {
    if (value > min) {
      setValue(value - 1);
    }
  };

  const handlePlus = () => {
    if (value < max) {
      setValue(value + 1);
    }
  };

  return (
    <div className="flex flex-col gap-0.5 w-full">
      <label className="caption-text text-primary-gray-800" htmlFor={label}>
        {label}{" "}
        {required && <span className="text-tertiary-severe-600">*</span>}{" "}
      </label>
      <div className="relative flex items-center gap-3 sm:gap-2">
        <div
          className={`absolute left-1.5 flex items-center justify-center size-9 cursor-pointer rounded-[7px] bg-primary-gray-100 ${
            value <= min && "opacity-50"
          }`}
          onClick={handleMinus}
        >
          <img src="/images/icons/minus.svg" />
        </div>
        <input
          id={label}
          className="body-small flex items-center text-center w-full py-3 text-primary-gray-800 outline outline-1 outline-primary-gray-200 rounded-xl hover:outline-primary-paradise-pink-500 focus:outline-2 focus:outline-primary-paradise-pink-500 transition-all duration-300"
          type="number"
          required
          value={value}
          max={max}
          min={min}
          onChange={(e) => setValue(parseInt(e.target.value))}
        />
        <div
          className={`absolute right-1.5 flex items-center justify-center size-9 cursor-pointer rounded-[7px] bg-primary-gray-100 ${
            value >= max && "opacity-50"
          }`}
          onClick={handlePlus}
        >
          <img src="/images/icons/plus.svg" />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
